import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { Outlet } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { colors } from "../../theme/colors";
import "./styles/SharedLayout.css";
import Navbar from "../../components/navbar/Navbar";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../hooks/useAuthContext";

const drawerWidth = 270;

function AdminSharedLayout(props) {
  const open = true;
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const user_type = user?.authenticatedUser?.role;
  const navigation =
    user_type === 1
      ? [
          {
            title: t("banniere"),
            paths: [
              { path: "/admin/gestion-des-bannieres/liste", title: t("list"), fullTitle: t("banniere_liste") },
              { path: "/admin/gestion-des-bannieres/ajout", title: t("add"), fullTitle: t("banniere_ajout") },
            ],
          },
          {
            title: t("marchand"),
            paths: [
              { path: "/admin/gestion-des-marchands/liste", title: t("list"), fullTitle: t("marchand_liste") },
              { path: "/admin/gestion-des-marchands/ajout", title: t("add"), fullTitle: t("marchand_ajout") },
            ],
          },
          {
            title: t("lanceur_alerte"),
            paths: [
              { path: "/admin/gestion-des-lanceurs-alerte/liste", title: t("list"), fullTitle: t("lanceur_alerte_liste") },
              { path: "/admin/gestion-des-lanceurs-alerte/ajout", title: t("add"), fullTitle: t("lanceur_alerte_ajout") },
            ],
          },
          {
            title: t("produit"),
            paths: [
              { path: "/admin/gestion-des-produits/liste", title: t("list"), fullTitle: t("produit_liste") },
              { path: "/admin/gestion-des-produits/ajout", title: t("add"), fullTitle: t("produit_ajout") },
            ],
          },
          {
            title: t("db"),
            paths: [
              { path: "/admin/gestion-des-bds/liste", title: t("list"), fullTitle: t("db_liste") },
              { path: "/admin/gestion-des-bds/ajout", title: t("add"), fullTitle: t("db_ajout") },
            ],
          },
          {
            title: t("images"),
            paths: [
              { path: "/admin/gestion-des-images/liste", title: t("list"), fullTitle: t("images") },
              { path: "/admin/gestion-des-images/ajout", title: t("add"), fullTitle: t("images") },
            ],
          },
        ]
      : user_type === 2
        ? [
            {
              title: t("navs_marchand.a_traiter"),
              paths: [{ path: "/marchand/gestion-des-produits-a-traiter/liste", title: t("list"), fullTitle: t("navs_marchand.a_traiter") }],
            },
            {
              title: t("navs_marchand.rappel"),
              paths: [{ path: "/marchand/gestion-des-rappel/liste", title: t("list"), fullTitle: t("navs_marchand.rappel") }],
            },
            {
              title: t("navs_marchand.suivi"),

              paths: [{ path: "/marchand/gestion-des-suivi/liste", title: t("list"), fullTitle: t("navs_marchand.suivi") }],
            },
            {
              title: t("forms.labels.tablette_vide"),
              paths: [{ path: "/marchand/gestion-des-tablette/liste", title: t("list"), fullTitle: t("forms.labels.tablette_vide") }],
            },
            {
              title: t("navs_marchand.deactivation_requests"),
              paths: [{ path: "/marchand/gestion-des-desactivations/liste", title: t("list"), fullTitle: t("forms.labels.deactivation_requests") }],
            },
            {
              title: t("navs_marchand.date_no_dipo"),
              paths: [{ path: "/marchand/gestion-des-date-non-dispo/liste", title: t("list"), fullTitle: t("navs_marchand.date_no_dipo") }],
            },
            {
              title: t("navs_marchand.produit"),
              paths: [
                { path: "/marchand/gestion-des-produit/liste", title: t("list"), fullTitle: t("navs_marchand.produit") },
                { path: "/marchand/gestion-des-produit/ajout", title: t("add"), fullTitle: t("navs_marchand.produit") },
              ],
            },
            {
              title: t("navs_marchand.rapport"),
              paths: [{ path: "/marchand/gestion-des-rapport/ajout", title: t("add"), fullTitle: t("navs_marchand.rapport") }],
            },
            {
              title: t("navs_marchand.users"),
              paths: [
                { path: "/marchand/gestion-des-utilisateurs/liste", title: t("list"), fullTitle: t("navs_marchand.users") },
                { path: "/marchand/gestion-des-utilisateurs/ajout", title: t("add"), fullTitle: t("navs_marchand.users") },
              ],
            },
            {
              title: t("navs_marchand.prefs"),
              paths: [{ path: "/marchand/gestion-des-preferences/ajout", title: t("add"), fullTitle: t("navs_marchand.prefs") }],
            },
            {
              title: t("navs_marchand.affichage_trait"),
              paths: [{ path: "/marchand/gestion-des-affichage/ajout", title: t("add"), fullTitle: t("navs_marchand.affichage_trait") }],
            },
            {
              title: t("navs_marchand.affichage_tab_delai"),
              paths: [{ path: "/marchand/gestion-des-standby/ajout", title: t("add"), fullTitle: t("navs_marchand.affichage_tab_delai") }],
            },
          ]
        : user_type === 4
          ? [
              {
                title: t("navs_alert_launcher.produit"),
                paths: [{ path: "/lanceur-alerte/gestion-des-produits/liste", title: t("list"), fullTitle: t("navs_alert_launcher.produit") }],
              },
              {
                title: t("navs_alert_launcher.rappel"),
                paths: [{ path: "/lanceur-alerte/gestion-des-produits/rappel/liste", title: t("list"), fullTitle: t("navs_alert_launcher.rappel") }],
              },
            ]
          : null;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const drawer = (
    <Box sx={{ marginTop: { xs: "160px", sm: "150px" } }}>
      <List sx={{ marginTop: "20px" }}>
        {navigation?.map((nav) => (
          <Box key={nav?.paths}>
            <ListItem key={1}>
              <Box display="flex" flexDirection="column" width="100%">
                <Typography marginLeft="5px" variant="text_md_white" fontWeight="600">
                  {nav?.title}
                </Typography>
                {nav?.paths?.map((l) => (
                  <NavLink key={l.path} onClick={mobileOpen && handleDrawerToggle} style={{ textDecoration: "none" }} id="navlink" className={({ isActive }) => (isActive ? "active" : "inactive")} to={l.path}>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <Typography sx={{ color: `black`, fontSize: "18px", fontWeight: "600" }}>{"• " + l.title}</Typography>
                    </Box>
                  </NavLink>
                ))}
              </Box>
            </ListItem>
          </Box>
        ))}
      </List>
    </Box>
  );
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box>
      <CssBaseline />
      <Navbar handleDrawerToggle={handleDrawerToggle} />
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Box display="flex">
        <Box>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: false,
            }}
            sx={{
              display: { xs: "inherit", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                background: colors?.gradient90,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Box sx={{ display: open ? { xs: "none", sm: "inherit" } : "none" }}>
          <Box width={drawerWidth} sx={{ display: open ? { xs: "none", sm: "inherit" } : "none" }}>
            <Drawer
              variant="permanent"
              sx={{
                width: drawerWidth,
                display: { xs: "none", sm: "inherit" },
                zIndex: 0,
                position: "fixed",
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  zIndex: 0,
                  flexShrink: 0,
                  [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
                  background: colors?.gradient90,
                },
              }}
              open={open}
            >
              {drawer}
            </Drawer>
          </Box>
        </Box>
        <Box flex={5} sx={{ p: 4, overflow: "hidden" }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

AdminSharedLayout.propTypes = {
  window: PropTypes.func,
};

export default AdminSharedLayout;
