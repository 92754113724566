import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo_fr from "../../assets/exppro-logo-600px-francais-white.svg";
import logo_en from "../../assets/exppro-logo-600px-anglais-white.svg";
import logo_es from "../../assets/exppro-logo-600px-spanish-white.svg";
import logo_fr_black from "../../assets/exppro-logo-600px-francais.svg";
import logo_en_black from "../../assets/exppro-logo-600px-anglais.svg";
import logo_es_black from "../../assets/exppro-logo-600px-spanish.svg";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CustomLanguageSelector from "../ui/CustomLanguageSelector";
import { colors } from "../../theme/colors";
import { Form, Formik } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";

const Navbar = ({ handleDrawerToggle }) => {
  const { user } = useAuthContext();
  const location = useLocation();
  const [path, setPath] = useState();
  const navigate = useNavigate();
  const { id } = useParams();
  const [upc, setUpc] = useState(user?.authenticatedUser?.role === 1 ? (location?.pathname.includes("/admin/gestion-des-produits/liste/search/") ? id : "") : user?.authenticatedUser.role === 4 ? (location?.pathname.includes("/lanceur-alerte/gestion-des-produits/liste/search/") ? id : "") : "");
  const { t } = useTranslation();
  useEffect(() => {
    switch (location?.pathname) {
      case "/admin/gestion-des-marchands/liste":
        return setPath(t("marchand_liste"));
      case "/admin/gestion-des-marchands/ajout":
        return setPath(t("marchand_ajout"));
      case "/admin/gestion-des-bannieres/liste":
        return setPath(t("banniere_liste"));
      case "/admin/gestion-des-bannieres/ajout":
        return setPath(t("banniere_ajout"));
      case "/admin/gestion-des-lanceurs-alerte/liste":
        return setPath(t("lanceur_alerte_liste"));
      case "/admin/gestion-des-lanceurs-alerte/ajout":
        return setPath(t("lanceur_alerte_ajout"));
      case "/admin/gestion-des-produits/liste":
        return setPath(t("produit_liste"));
      case "/admin/gestion-des-produits/ajout":
        return setPath(t("produit_ajout"));
      case "/admin/gestion-des-bds/liste":
        return setPath(t("bd_liste"));
      case "/admin/gestion-des-bds/ajout":
        return setPath(t("bd_ajout"));
      default:
        setPath("");
    }
  }, [location]);
  const handleSubmit = () => {
    if (user?.authenticatedUser?.role === 1 && upc !== "") {
      if (location.pathname.includes("/admin/gestion-des-produits/liste/search/")) {
        navigate(`/admin/gestion-des-produits/liste/search/${upc}`);
        document?.location?.reload();
      } else {
        navigate(`/admin/gestion-des-produits/liste/search/${upc}`);
      }
    }
    if (user?.authenticatedUser?.role === 4 && upc !== "") {
      if (location.pathname.includes("/lanceur-alerte/gestion-des-produits/liste/search/")) {
        navigate(`/lanceur-alerte/gestion-des-produits/liste/search/${upc}`);
        document?.location?.reload();
      } else {
        navigate(`/lanceur-alerte/gestion-des-produits/liste/search/${upc}`);
      }
    }
    if (user?.authenticatedUser?.role === 2 && upc !== "") {
      if (location.pathname.includes("/marchand/gestion-des-produit/liste/search/")) {
        navigate(`/marchand/gestion-des-produit/liste/search/${upc}`);
        document?.location?.reload();
      } else {
        navigate(`/marchand/gestion-des-produit/liste/search/${upc}`);
      }
    }
  };

  const handleLogout = () => {
    Api.post(
      Urls.BLACK_LIST_REFRESH_TOKEN,
      {
        refresh: user?.refresh,
      },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${user?.access}` },
      }
    )
      .then((res) => {
        localStorage.removeItem("user");
        window.location.href = "/";
      })
      .catch((err) => {
        localStorage.removeItem("user");
        window.location.href = "/";
      });
  };
  return (
    <Box sx={{ zIndex: 9999999, position: "sticky", top: 0 }}>
      <Box
        sx={{
          zIndex: "999999999999",
          boxShadow: "0",
          backgroundColor: `white`,
          padding: "0",
          minHeight: { xs: "80px", sm: "160px", md: "160px", lg: "160px" },
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box sx={{ padding: "0", margin: "0", color: "white", display: "flex", alignItems: "center", flexDirection: "column" }}>
            <Box sx={{ zIndex: 99999999, flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" }, justifyContent: "space-between", width: "100%", alignItems: "center" }} display="flex">
              <Box position="relative" sx={{ display: { xs: "none", sm: "inherit", md: "inherit", lg: "inherit" } }} flex={1}>
                <Box sx={{ background: colors.gradient90 }} padding="0px 60px 0px 0px">
                  {t("logo") === "logo_fr" && <img src={logo_fr} style={{ marginLeft: "10px", aspectRatio: "2/1", height: "130px", width: "auto" }} alt="expo" />}
                  {t("logo") === "logo_en" && <img src={logo_en} style={{ marginLeft: "10px", aspectRatio: "2/1", height: "130px", width: "auto" }} alt="expo" />}
                  {t("logo") === "logo_es" && <img src={logo_es} style={{ marginLeft: "10px", aspectRatio: "2/1", height: "130px", width: "auto" }} alt="expo" />}
                </Box>
                <div style={{ left: "280px", top: "0px", borderLeft: "50px solid transparent", borderRight: "50px solid transparent", borderBottom: "137px solid white", position: "absolute", height: "0", width: "0" }}></div>
              </Box>
              <Box justifyContent="space-between" display="flex" alignItems="center" position="relative" sx={{ display: { xs: "inherit", sm: "none", md: "none", lg: "none" }, background: "white", width: "100%" }}>
                <IconButton color="black" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ display: { xs: "inherit", sm: "none", md: "none", lg: "none" }, borderRadius: "0", mr: 2, marginLeft: "10px" }}>
                  <MenuIcon />
                </IconButton>
                <Box display="flex" alignItems="cente" margin="0px 40px 0px 10px">
                  {t("logo") === "logo_fr" && <img src={logo_fr_black} style={{ marginLeft: "10px", aspectRatio: "2/1", height: "50px", width: "auto" }} alt="expo" />}
                  {t("logo") === "logo_en" && <img src={logo_en_black} style={{ marginLeft: "10px", aspectRatio: "2/1", height: "50px", width: "auto" }} alt="expo" />}
                  {t("logo") === "logo_es" && <img src={logo_es_black} style={{ marginLeft: "10px", aspectRatio: "2/1", height: "50px", width: "auto" }} alt="expo" />}
                </Box>
              </Box>
              <Box sx={{ zIndex: "9999999" }} flexDirection="column" flex={1} display="flex" marginBottom="10px" alignItems="center" justifyContent="center">
                <Box display="flex" flexDirection="column" alignItems="center" backgroundColor={colors?.gradient90} borderRadius="8px">
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{ background: colors?.gradient90, padding: "10px", borderRadius: "8px" }}>
                    <Formik
                      initialValues={{
                        search: "",
                      }}
                      onSubmit={handleSubmit}
                    >
                      {({ setFieldValue, values }) => (
                        <Form>
                          <Box display="flex" alignItems="center" justifyContent="center">
                            <Box maxWidth="300px">
                              <TextField
                                onChange={(e) => {
                                  setUpc(e.target.value);
                                  setFieldValue("search", e.target?.value);
                                }}
                                value={upc}
                                placeholder={t("search_product")}
                                sx={{ width: "100%" }}
                              />
                            </Box>
                            <IconButton type="submit" sx={{ borderRadius: "0" }}>
                              <SearchIcon sx={{ color: "white", fontSize: 30 }} />
                            </IconButton>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                    <Box sx={{ zIndex: "999999999999" }}>
                      <CustomLanguageSelector />
                    </Box>
                  </Box>
                </Box>
                <Box marginTop="10px" sx={{ display: { xs: "inherit", sm: "inherit", md: "none", lg: "none" } }}>
                  <Button onClick={handleLogout} variant="primary">
                    {t("forms.submit.se_deconnecter")}
                  </Button>
                </Box>
              </Box>
              <Box position="relative" height="137px" sx={{ display: { xs: "none", sm: "none", md: "inherit", lg: "inherit" } }} flex={1} display="flex" alignItems="top" justifyContent="end">
                <Box marginRight={"25px"}>
                  <Box paddingTop="25px" sx={{ display: "flex", flexDirection: "column", gap: "10px", textAlign: "center" }}>
                    <Typography fontSize="24px" color="black" fontWeight="600">
                      {user?.authenticatedUser?.role === 2 ? user?.authenticatedUser?.store_manager_code : user?.authenticatedUser?.role === 1 ? "Admin" : ""}
                    </Typography>
                    <Button onClick={handleLogout} variant="primary">
                      {t("forms.submit.se_deconnecter")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ zIndex: 9999999, position: "relative" }} paddingLeft="60px" minHeight="30px" backgroundColor="black" width="100%" display="flex" alignItems="center" gap="40px" flexWrap="wrap">
            <Typography sx={{ display: { xs: "none", sm: "inherit", lg: "inherit", md: "inherit" } }} fontSize="18px" fontWeight="600" color="red">
              {moment(new Date()).format("DD/MM/YYYY")}
            </Typography>
            <Typography fontSize="18px" fontWeight="600" color="white">
              {path}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Navbar;
