import { Autocomplete, Box, Button, Checkbox, CircularProgress, FormControlLabel, TextField, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";
import { ErrorMessage, Form, Formik } from "formik";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useTranslation } from "react-i18next";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import * as yup from "yup";
import { action_types_list_en, action_types_list_es, action_types_list_fr } from "../../../assets/data/ActionTypes";
import { useState } from "react";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { categories_list_en, categories_list_en_all, categories_list_es_all, categories_list_fr_all } from "../../../assets/data/categories";

function Rapport() {
  const { user } = useAuthContext();
  const lang = localStorage.getItem("i18nextLng");
  let action_types = lang === "fr" ? action_types_list_fr : lang === "en" ? action_types_list_en : lang === "es" ? action_types_list_es : {};
  const [loading, setLoading] = useState(false);
  const access_token = user?.access;
  const { t } = useTranslation();
  const categories = lang === "fr" ? categories_list_fr_all : lang === "en" ? categories_list_en_all : categories_list_es_all;

  const initialValues = {
    start_date: null,
    end_date: null,
    category_value: [],
    category: [],
    type: [],
  };
  const validationSchema = yup.object({
    start_date: yup.date().required(t("validation_required")),
    category_value: yup.array().min(1, t("validation_required")).required(t("validation_required")),
    end_date: yup.date().required(t("validation_required")),
    type: yup.array().min(1, t("validation_required")).required(t("validation_required")),
  });

  const GeneratePDF = (values) => {
    setLoading(true);

    Api.post(
      Urls.GenereatePdf,
      {
        start_date: values?.start_date,
        category_types: values.category_value?.includes("ALL") ? categories_list_en?.map((cat) => cat?.value) : values.category_value,
        end_date: values?.end_date,
        action_types: values?.type,
      },
      {
        headers: { "Content-Type": "application/json", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
        responseType: "blob",
      }
    )
      .then((res) => {
        setLoading(false);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        // Create a temporary link element
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "generated_report.pdf"); // Name the file
        // Append the link to the document and click it
        document.body.appendChild(link);
        link.click();
        // Clean up
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <Box>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values) => {
          GeneratePDF(values);
        }}
      >
        {({ errors, values, setFieldValue }) => (
          <Form>
            <Box display="flex" flexDirection="column" gap="24px">
              <Box textAlign="center" display="flex" flexDirection="row" alignItems={"center"} gap="10px">
                <Typography sx={{ fontSize: "15px", fontWeight: "600", color: colors.main }}>{t("forms.labels.start_date")}</Typography>{" "}
                <Box>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="yyyy-MM-dd"
                      value={values.start_date}
                      maxDate={new Date()}
                      InputProps={{ sx: { "& .MuiSvgIcon-root": { color: colors?.main, height: "30px", width: "30px" } } }}
                      onChange={(value) => {
                        setFieldValue("start_date", moment(value).format("YYYY-MM-DD"));
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <ErrorMessage name="start_date" component={FormikErrorText} color={colors.main} width="250px" />
                </Box>
              </Box>
              <Box textAlign="center" display="flex" flexDirection="row" alignItems={"center"} gap="10px">
                <Typography sx={{ fontSize: "15px", fontWeight: "600", color: colors.main }}>{t("forms.labels.end_date")}</Typography>{" "}
                <Box>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="yyyy-MM-dd"
                      value={values.end_date}
                      minDate={values?.start_date}
                      maxDate={new Date()}
                      InputProps={{ sx: { "& .MuiSvgIcon-root": { color: colors?.main, height: "30px", width: "30px" } } }}
                      onChange={(value) => {
                        setFieldValue("end_date", moment(value).format("YYYY-MM-DD"));
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <ErrorMessage name="end_date" component={FormikErrorText} color={colors.main} width="250px" />
                </Box>
              </Box>
              <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="10px">
                <Box>
                  <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                    {t("forms.labels.category")}
                  </Typography>
                </Box>
                <Box>
                  <Autocomplete
                    multiple
                    sx={{ minWidth: "262px", maxWidth: "263px", fontSize: "18px" }}
                    options={categories?.filter((cat) => cat?.value !== "AUC")}
                    disableClearable
                    value={values.category}
                    getOptionLabel={(e) => e?.name}
                    isOptionEqualToValue={(option, value) => option?.value === value?.value}
                    onChange={(event, value) => {
                      if (value !== null) {
                        setFieldValue(
                          "category_value",
                          value?.map((cat) => cat?.value)
                        );
                        setFieldValue("category", value);
                      } else {
                        setFieldValue("category", null);
                        setFieldValue("category_value", "");
                      }
                    }}
                    renderInput={(params) => <TextField fullWidth {...params} name="autocomplete" placeholder={t("forms.labels.category")} />}
                  />
                </Box>
                <ErrorMessage name="category_value" component={FormikErrorText} color={colors.main} width="250px" />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                {action_types?.map((action) => (
                  <>
                    {action?.des !== "CD" && (
                      <FormControlLabel
                        key={action.des}
                        sx={{
                          fontWeight: 900,
                          color: "black",
                          fontSize: 20,
                        }}
                        control={
                          <Checkbox
                            checked={values?.type?.includes(action.des) || false}
                            value={action.des}
                            onChange={(e) => {
                              const newType = e.target.checked ? [...values?.type, action.des] : values?.type?.filter((f) => f !== action.des);
                              setFieldValue("type", newType);
                            }}
                          />
                        }
                        label={action?.name}
                      />
                    )}
                  </>
                ))}
                <ErrorMessage name="type" component={FormikErrorText} color={colors.main} width="250px" />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                <Button disabled={loading} variant="primary" type="submit">
                  {t("gen")}
                </Button>
                {loading && <CircularProgress />}
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
export default Rapport;
