import { Box, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { Urls } from "../../api/Urls";
import Api from "../../api/Api";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { toast } from "react-toastify";

const Redirect = () => {
  const { user } = useAuthContext();
  const access_token = user?.access;
  const navigate = useNavigate();
  useEffect(() => {
    Api.get(Urls?.GET_RECALL_NOTIFS_EMPLOYEE, {
      headers: { "Content-type": "application/json", Authorization: `Bearer ${access_token}` },
      params: {
        treated: false,
      },
    })
      .then((res) => {
        if (res?.data?.results?.length > 0) {
          navigate("/employee/gestion-des-rappels/liste", { state: { treate: "not_traated" } });
        } else {
          Api.get(Urls?.GET_PRODUITS_A_TRAITER, {
            headers: { "Content-type": "application/json", Authorization: `Bearer ${access_token}` },
            params: {
              treated: false,
            },
          })
            .then((res) => {
              if (res?.data?.results?.length > 0) {
                navigate("/employee/gestion-des-traiter/liste");
              } else {
                navigate("/employee/gestion-des-suivis/liste");
              }
            })
            .catch((err) => {
              toast.error(err?.response?.data?.details);
            });
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.details);
      });
  }, []);
  return (
    <Box sx={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
      <CircularProgress />
    </Box>
  );
};
export default Redirect;
