import { Alert, Box, Button, Checkbox, FormControlLabel, IconButton, List, ListItem, Modal, TextField, Typography } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/colors";
import * as yup from "yup";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Urls } from "../../../api/Urls";
import Api from "../../../api/Api";
import { categories_list_en, categories_list_es, categories_list_fr } from "../../../assets/data/categories";

import Loading from "../../../components/ui/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { CloseOutlined } from "@mui/icons-material";
import { modalStyle } from "../../../theme/ModalStyle";
const UpdateUtilisateur = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { id } = useParams();
  const [employee, setEmployee] = useState({});
  const [sendToUser, setSendToUser] = useState({ username: false, password: false });
  const [openUpdate, setOpenUpdate] = useState({ state: false, data: null, page: null });
  const [update, setUpdate] = useState(false);
  const [initialValuesUpdateCredentialsEmail, setinitialValuesUpdateCredentialsEmail] = useState({
    courriel: "",
  });
  const [initialValuesUpdateCredentialsUser, setinitialValuesUpdateCredentialsUser] = useState({
    username: "",
  });
  const initialValuesUpdateCredentialsPass = {
    password: "",
  };
  const { user } = useAuthContext();
  const lang = localStorage?.getItem("i18nextLng");
  const access_token = user?.access;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [checkAllProd, setCheckAllProd] = useState(false);
  let categories = lang === "fr" ? categories_list_fr : lang === "en" ? categories_list_en : categories_list_es;
  const [successCred, setSuccessCred] = useState("");
  const [errorCred, setErrorCred] = useState("");
  const [initial_values, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    username: "",
    visible: [],
    email: "",
    password: "",
  });
  const validationSchema = yup.object({
    first_name: yup?.string().required(t("validation_required")),
    visible: !checkAllProd && yup?.array().min(1, t("validation_required")).required(t("validation_required")),
    last_name: yup?.string().required(t("validation_required")),
  });
  const validationSchemaUpdateEmail = yup?.object({
    courriel: yup?.string().email(t("forms.validation.email")).required(t("validation_required")),
  });
  const validationSchemaUpdateUsername = yup?.object({
    username: yup?.string().required(t("validation_required")),
  });
  const validationSchemaUpdateCredPass = yup?.object({
    password: yup?.string().min(8, t("forms.validation.8_char_min")).required(t("validation_required")),
  });

  //HANDLE ERROR
  function handleErrorCred(error) {
    setErrorCred({
      username: error?.username,
      password: error?.password,
      email: error?.email,
    });
  }
  function handleError(error) {
    setError({
      visible_categories: error?.visible_categories,
    });
  }
  useEffect(() => {
    if (id) {
      Api.get(Urls?.GetEmloyeeByID(id), {
        headers: { "Content-Type": "application/json", "Accept-language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
      })
        .then((res) => {
          setEmployee(res?.data);
          setInitialValues({
            first_name: res?.data?.first_name,
            last_name: res?.data?.last_name,
            visible: res?.data?.visible_categories,
          });
          setinitialValuesUpdateCredentialsEmail({
            courriel: res?.data?.user?.email,
          });
          setinitialValuesUpdateCredentialsUser({
            username: res?.data?.user?.username,
          });
          setLoading(false);
        })
        .catch((err) => {
          setError(t("error_500"));
          setLoading(false);
        });
    }
  }, [update]);

  //HANDLE SBUMIT EDIT MANAGER
  const handleSubmitCredEmail = (values) => {
    handleClickCloseUpdate();
    setErrorCred(null);
    if (values?.courriel !== initialValuesUpdateCredentialsEmail?.courriel) {
      Api.patch(
        Urls.UpdateEmployeeEmail(employee?.user?.id),
        {
          email: values?.courriel,
        },
        {
          headers: { "Content-Type": "application/json", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
        }
      )
        .then((res) => {
          setSuccessCred(t("forms.success.update"));
          setTimeout(() => {
            setSuccessCred("");
            setUpdate(!update);
          }, 3000);
        })
        .catch((err) => {
          handleErrorCred(err?.response?.data);
        });
    }
  };

  //HANDLE SBUMIT EDIT MANAGER
  const handleSubmitCreduser = (values) => {
    handleClickCloseUpdate();
    setErrorCred(null);
    if (values?.username !== initialValuesUpdateCredentialsUser?.username) {
      Api.patch(
        Urls.UpdateEmployeeUsername(employee?.user?.id),
        {
          ...(values?.username && values?.username !== initialValuesUpdateCredentialsUser?.username && { username: values?.username }),
        },
        {
          headers: { "Content-Type": "application/json", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
          params: {
            send_to_user: sendToUser?.username,
          },
        }
      )
        .then((res) => {
          setSuccessCred(t("forms.success.update"));
          setTimeout(() => {
            setSuccessCred("");
            setUpdate(!update);
          }, 3000);
        })
        .catch((err) => {
          setLoading(false);
          handleErrorCred(err?.response?.data);
        });
    }
  };
  //HANDLE SBUMIT EDIT MANAGER
  const handleSubmitCredPass = (values) => {
    handleClickCloseUpdate();

    setErrorCred(null);
    Api.patch(
      Urls.UpdateEmployeePassword(employee?.user?.id),
      {
        password: values?.password,
      },
      {
        headers: { "Content-Type": "application/json", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
        params: {
          send_to_user: sendToUser?.password,
        },
      }
    )
      .then((res) => {
        setSuccessCred(t("forms.success.update"));

        setTimeout(() => {
          setSuccessCred("");
          setUpdate(!update);
        }, 3000);
      })
      .catch((err) => {
        handleErrorCred(err?.response?.data);
      });
  };
  const handleUpdateUserProfile = (values) => {
    handleClickCloseUpdate();
    let array = categories?.map((cat) => cat?.value);
    setError("");
    Api.patch(
      Urls.UpdateStoreEmployeeProfile(employee?.id),
      {
        first_name: values?.first_name,
        last_name: values?.last_name,
        visible_categories: checkAllProd && values?.visible?.length === 0 ? array : checkAllProd && values?.visible?.length > 0 ? array.filter((x) => !values?.visible?.includes(x)) : values?.visible,
      },
      {
        headers: { "Content-Type": "application/json", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setSuccessCred(t("forms.success.update"));
        setCheckAllProd(false);
        setUpdate(!update);
        setTimeout(() => {
          setSuccessCred("");
        }, 3000);
      })
      .catch((err) => {
        handleError(err?.response?.data);
      });
  };
  const handleClickCloseUpdate = () => {
    setOpenUpdate({ state: false, data: null, page: null });
  };

  return (
    <Box>
      {loading && <Loading />}
      {!loading && (
        <Box>
          <Formik initialValues={initial_values} onSubmit={(values) => setOpenUpdate({ state: true, data: values, page: "profile" })} validationSchema={validationSchema} enableReinitialize>
            {({ errors, values, setFieldValue }) => (
              <Form>
                <Box maxWidth="500px" display="flex" flexDirection="column" gap="16px">
                  <Box>
                    <Button onClick={() => navigate("/marchand/gestion-des-utilisateurs/liste/" + id + "/logs/" + employee?.id)} variant="primary">
                      {t("logs")}
                    </Button>
                  </Box>
                  <Box>
                    <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                      <Box flex={1}>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.nom")}
                        </Typography>
                      </Box>
                      <Box>
                        <Field name="last_name" type="input" placeholder={t("forms.labels.nom")} as={TextField} />
                        <ErrorMessage name="last_name" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                      <Box flex={1}>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.prenom")}
                        </Typography>
                      </Box>
                      <Box>
                        <Field name="first_name" type="input" placeholder={t("forms.labels.prenom")} as={TextField} />
                        <ErrorMessage name="first_name" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.cat_choice")}
                      </Typography>
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkAllProd}
                            value={checkAllProd}
                            onChange={(e) => {
                              setCheckAllProd(e.target.checked);
                            }}
                          />
                        }
                        label={t("forms.labels.all_prod")}
                      />
                    </Box>
                    {!checkAllProd && (
                      <Box>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.seulement")}
                        </Typography>
                        <Box sx={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
                          {categories?.map((cat, index) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  key={index}
                                  value={cat?.value}
                                  checked={values?.visible?.includes(cat.value) || false}
                                  onChange={(e) => {
                                    const newType = e.target.checked ? [...values?.visible, cat.value] : values?.visible?.filter((f) => f !== cat.value);
                                    setFieldValue("visible", newType);
                                  }}
                                />
                              }
                              label={cat?.name}
                            />
                          ))}
                        </Box>
                        <ErrorMessage name="visible" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    )}
                    {checkAllProd && (
                      <Box>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.sauf")}
                        </Typography>
                        <Box sx={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
                          {categories?.map((cat, index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  value={cat?.value}
                                  checked={values?.visible?.includes(cat.value) || false}
                                  onChange={(e) => {
                                    const newType = e.target.checked ? [...values?.visible, cat.value] : values?.visible?.filter((f) => f !== cat.value);
                                    setFieldValue("visible", newType);
                                  }}
                                />
                              }
                              label={cat?.name}
                            />
                          ))}
                        </Box>

                        <ErrorMessage name="visible" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    )}
                    {error && (
                      <Box>
                        <Alert severity="error">
                          <List>
                            {error?.visible_categories && (
                              <ListItem>
                                {t("forms.labels.cat_choice")} : {error?.visible_categories[0]}
                              </ListItem>
                            )}
                          </List>
                        </Alert>
                      </Box>
                    )}
                  </Box>
                  <Box>
                    <Button type="submit" variant="primary">
                      {t("forms.submit.save")}
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
          <Box display="flex" flexDirection="column" gap="24px" marginTop="24px">
            <Formik initialValues={initialValuesUpdateCredentialsEmail} enableReinitialize validationSchema={validationSchemaUpdateEmail} onSubmit={(values) => setOpenUpdate({ state: true, data: values, page: "email" })}>
              {({ errors }) => (
                <Form>
                  <Box maxWidth="700px" display="flex" flexDirection="row" gap="16px" flexWrap="wrap" alignItems="end">
                    <Box>
                      <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                        <Box flex={1}>
                          <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                            EMAIL
                          </Typography>
                        </Box>
                        <Box>
                          <Field type="input" name="courriel" placeholder="EMAIL" as={TextField} />
                          <ErrorMessage name="courriel" component={FormikErrorText} color={colors.main} width="250px" />
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Button type="submit" variant="primary">
                        {t("forms.submit.save")}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
            <Formik initialValues={initialValuesUpdateCredentialsUser} enableReinitialize validationSchema={validationSchemaUpdateUsername} onSubmit={(values) => setOpenUpdate({ state: true, data: values, page: "username" })}>
              {({ errors }) => (
                <Form>
                  <Box>
                    <Box maxWidth="700px" display="flex" flexDirection="row" gap="16px" flexWrap="wrap" alignItems="end">
                      <Box>
                        <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                          <Box flex={1}>
                            <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                              {t("forms.labels.utilisateur")}
                            </Typography>
                          </Box>
                          <Box>
                            <Field name="username" placeholder={t("forms.labels.utilisateur")} as={TextField} />
                            <ErrorMessage name="username" component={FormikErrorText} color={colors.main} width="250px" />
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Button type="submit" variant="primary">
                          {t("forms.submit.save")}
                        </Button>
                      </Box>
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sendToUser?.username}
                            value={sendToUser?.username}
                            onChange={(e) => {
                              setSendToUser((sendToUser) => {
                                return { username: e.target?.checked, password: sendToUser?.password };
                              });
                            }}
                          />
                        }
                        label={t("send_by_email")}
                      />
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
            <Formik initialValues={initialValuesUpdateCredentialsPass} enableReinitialize validationSchema={validationSchemaUpdateCredPass} onSubmit={(values) => setOpenUpdate({ state: true, data: values, page: "password" })}>
              {({ errors }) => (
                <Form>
                  <Box>
                    <Box maxWidth="700px" display="flex" flexDirection="row" gap="16px" flexWrap="wrap" alignItems="end">
                      <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                        <Box flex={1}>
                          <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                            {t("forms.labels.mot_de_passe")}
                          </Typography>
                        </Box>
                        <Box>
                          <Field name="password" placeholder={t("forms.labels.mot_de_passe")} as={TextField} />
                          <ErrorMessage name="password" component={FormikErrorText} color={colors.main} width="250px" />
                        </Box>
                      </Box>
                      <Box>
                        <Button type="submit" variant="primary">
                          {t("forms.submit.save")}
                        </Button>
                      </Box>
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sendToUser?.password}
                            value={sendToUser?.password}
                            onChange={(e) => {
                              setSendToUser((sendToUser) => {
                                return { username: sendToUser?.username, password: e.target?.checked };
                              });
                            }}
                          />
                        }
                        label={t("send_by_email")}
                      />
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
          {successCred && <Alert severity="success">{successCred}</Alert>}
          {errorCred && (
            <Alert severity="error">
              <Box>
                <List>
                  {errorCred?.username && (
                    <ListItem>
                      {t("forms.labels.utilisateur")} : {errorCred?.username}
                    </ListItem>
                  )}
                  {errorCred?.password && (
                    <ListItem>
                      {t("forms.labels.mot_de_passe")} : {errorCred?.password}
                    </ListItem>
                  )}
                  {errorCred?.detail && <ListItem>{errorCred?.detail}</ListItem>}
                  {errorCred?.email && (
                    <ListItem>
                      {t("forms.labels.courriel")} : {errorCred?.email}
                    </ListItem>
                  )}
                </List>
              </Box>
            </Alert>
          )}
        </Box>
      )}
      {/* modal confirmation update */}
      <Modal open={openUpdate?.state} onClose={handleClickCloseUpdate}>
        <Box sx={modalStyle}>
          <Box position="absolute" top="2px" right="2px">
            <IconButton onClick={handleClickCloseUpdate}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column" gap="24px">
            <Box textAlign="center">
              <Typography variant="text_lg_black">{t("forms.submit.save")}</Typography>
            </Box>
            <Box textAlign="center">
              <Typography variant="text_md_black">{t("forms.messages.update")}</Typography>
            </Box>
            <Box display="flex" justifyContent="center" gap="20px">
              <Button
                onClick={() => {
                  openUpdate?.page === "email" ? handleSubmitCredEmail(openUpdate?.data) : openUpdate?.page === "username" ? handleSubmitCreduser(openUpdate?.data) : openUpdate?.page === "password" ? handleSubmitCredPass(openUpdate?.data) : handleUpdateUserProfile(openUpdate?.data);
                }}
                variant="primary"
              >
                {t("forms.submit.save")}
              </Button>
              <Button onClick={handleClickCloseUpdate} variant="primary">
                {t("forms.submit.close")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* modal confirmation update */}
    </Box>
  );
};
export default UpdateUtilisateur;
