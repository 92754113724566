import axios from "axios";
import { Urls } from "../api/Urls";

let user = null;
let dispatch = null;

const Api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const configureApi = (authUser, authDispatch) => {
  user = authUser;
  dispatch = authDispatch;
};

const refreshToken = (refresh) => {
  return new Promise((resolve, reject) => {
    Api.post(Urls.REFRESH_TOKEN, { refresh })
      .then((response) => {
        const newAccessToken = response?.data?.access;
        const newRefreshToken = response?.data?.refresh;
        const updatedUser = { ...user, access: newAccessToken, refresh: newRefreshToken };
        localStorage.setItem("user", JSON.stringify(updatedUser));
        dispatch({ type: "LOGIN", payload: updatedUser });
        resolve(newAccessToken);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          // Remove the user from localStorage and logout
          localStorage.removeItem("user");
          dispatch({ type: "LOGOUT" });
          document.location.reload();
        }
        reject(err);
      });
  });
};

Api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if the error is related to token expiration and if the request has not been retried yet
    if (error?.response?.status === 401 && error?.response?.data?.code === "token_not_valid" && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!user?.refresh) {
        // No refresh token available, logout the user
        localStorage.removeItem("user");
        dispatch({ type: "LOGOUT" });
        document.location.reload();
        return Promise.reject(error);
      }

      try {
        const newAccessToken = await refreshToken(user.refresh);
        // Update the authorization header with the new access token
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

        // Retry the original request with the new access token
        return Api(originalRequest);
      } catch (refreshError) {
        // If refreshing the token failed, reject the request with the error
        return Promise.reject(refreshError);
      }
    }

    // If the error is not related to token expiration, reject the request
    return Promise.reject(error);
  }
);

export default Api;
