import { Box, Button } from "@mui/material";
import NavbarEmployee from "./NavbarEmployer";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

const EmployeeSharedLayout = ({ update, setUpdate }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      <NavbarEmployee updateCount={update} />
      <Box display={"flex"} justifyContent="center" maxWidth={"100%"}>
        <Box padding="0 10px" sx={{ maxWidth: { xs: "370px", sm: "520px", md: "520px", lg: "520px" } }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
export default EmployeeSharedLayout;
