import { Alert, Box, CircularProgress, IconButton, TextField, Typography } from "@mui/material";
import StyledTableComponent from "../../../components/ui/StyledTableComponent";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import EditIcon from "@mui/icons-material/Edit";
import { columns_marchand_en, columns_marchand_es, columns_marchand_fr } from "../../../columns/marchandColumns";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../theme/colors";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import debounce from "debounce";
import { useTranslation } from "react-i18next";
const ListMarchand = () => {
  const { user } = useAuthContext();
  let access_token = user?.access;
  const navigate = useNavigate();
  let limit = 25;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState();
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [sorting, setSorting] = useState({
    name: "",
    direction: "",
  });
  const [enterprise, setEnterprise] = useState("");
  const [searchEnterprise, setSearchEnterprise] = useState("");
  const lang = localStorage.getItem("i18nextLng");
  const columns = lang === "fr" ? columns_marchand_fr : lang === "en" ? columns_marchand_en : columns_marchand_es;
  useEffect(() => {
    setLoading(true);
    Api.get(Urls?.GET_MANAGERS, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        offset: 0,
        limit: limit,
        ...(enterprise && { enterprise_name: enterprise }),
        ...(sorting?.name && { order_by: sorting?.name }),
        ...(sorting?.direction && { order_direction: sorting?.direction === "up" ? "asc" : "desc" }),
      },
    })

      .then((res) => {
        setData((data) => {
          let array = [];
          let d = res?.data?.results;
          for (let index = 0; index < d.length; index++) {
            let element = d[index];
            element = { id: element?.id, franchise_name: element?.franchise?.name, ...element };
            array.push(element);
          }
          return array;
        });
        setResponse(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err?.response?.data?.details);
        setLoading(false);
      });
  }, [searchEnterprise, sorting]);
  const handlePagination = (url) => {
    Api.get(url, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })

      .then((res) => {
        setData((data) => {
          let array = [];
          let d = res?.data?.results;
          for (let index = 0; index < d.length; index++) {
            let element = d[index];
            element = { id: element?.id, franchise_name: element?.franchise?.name, ...element };
            array.push(element);
          }
          return array;
        });
        setResponse(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err?.response?.data?.details);
        setLoading(false);
      });
  };
  const Buttons = ({ id }) => {
    return (
      <>
        <IconButton onClick={() => navigate("/admin/gestion-des-marchands/liste/" + id)}>
          <EditIcon sx={{ color: `${colors.main}` }} />
        </IconButton>
      </>
    );
  };
  const Pagination = () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="end">
        {response?.previous && (
          <IconButton onClick={() => handlePagination(response?.previous)}>
            <ArrowLeft sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
        <Box>
          <Typography variant="text_md_black">{response?.count}</Typography>
        </Box>
        {response?.next && (
          <IconButton onClick={() => handlePagination(response?.next)}>
            <ArrowRight sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
      </Box>
    );
  };
  //debounced search
  const debouncedSearch = React.useMemo(() => {
    return debounce((enterprise) => {
      setSearchEnterprise(enterprise);
    }, 700);
  }, []);
  useEffect(() => {
    debouncedSearch(enterprise);
  }, [enterprise]);
  return (
    <Box display="flex" flexDirection="column" gap="24px">
      <Box display="flex" gap="10px" alignItems="center" maxWidth="500px">
        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
          {t("forms.labels.entreprise")}
        </Typography>
        <TextField placeholder={t("forms.labels.entreprise")} type="input" value={enterprise} onChange={(e) => setEnterprise(e.target.value)} />
      </Box>
      {error && !loading && <Alert severity="error">{error}</Alert>}
      {!error && !loading && <StyledTableComponent sorting={sorting} setSorting={setSorting} setData={setData} data={data} columns={columns} Buttons={Buttons} pagination={<Pagination />} />}
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};
export default ListMarchand;
