import { Alert, Autocomplete, Box, Button, Checkbox, FormControlLabel, IconButton, List, ListItem, TextField, Typography } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/colors";
import * as yup from "yup";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import { useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Urls } from "../../../api/Urls";
import Api from "../../../api/Api";
import Loading from "../../../components/ui/Loading";
import { CloseOutlined, SelectAll, UploadFile } from "@mui/icons-material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import { categories_list_en, categories_list_es, categories_list_fr } from "../../../assets/data/categories";
const AjoutProduitM = () => {
  const [error, setError] = useState("");
  const { user } = useAuthContext();
  const lang = localStorage?.getItem("i18nextLng");
  const [success, setSuccess] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [noDispo, setNoDispo] = useState(false);
  const [faillureAdding, setFaillureAdding] = useState([]);
  const [successAdding, setSuccessAdding] = useState([]);
  const access_token = user?.access;
  const { t } = useTranslation();
  const categories = lang === "fr" ? categories_list_fr : lang === "en" ? categories_list_en : categories_list_es;
  const initial_values = {
    upc: "",
    format: "",
    designation: "",
    use_admin: true,
    no_date: false,
    category: categories[0],
    category_value: categories[0]?.value,
    dep: null,
    sub_dep: null,
    empty_shelf: false,
    localisation: "",
    prix: "",
    prix_red: "",
    fournisseur: "",
    expiration_date: "",
    note: "",
    image: null,
    alert: 30,
    active: false,
  };
  //validation schema
  const validateFileType = (file) => {
    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (allowedTypes.includes(file.type)) {
        return true;
      }
    }
    return false;
  };
  const validateFileSize = (file) => {
    if (file) {
      const maxSize = 5 * 1024 * 1024;
      if (file.size <= maxSize) {
        return true;
      }
    }
    return false;
  };
  const validationSchema = yup.object({
    upc: yup?.string().required(t("validation_required")),
    category_value: yup?.string().nonNullable(t("validation_required")).required(t("validation_required")),
    designation: yup?.string().required(t("validation_required")),
    image: yup
      .mixed()
      .nullable()
      .test("fileType", "Invalid file type", (file) => (!file ? true : validateFileType(file)))
      .test("fileSize", "File size exceeds 5MB", (file) => (!file ? true : validateFileSize(file))),
  });

  //HANDLE SBUMIT CREATE PRODUCT
  const [progressFile, setProgressFile] = useState(0);
  var config = {
    headers: { "Content-Type": "multipart/form-data", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressFile(percentCompleted);
    },
  };
  const handleSubmit = (values) => {
    setError(null);
    setLoadingSubmit(true);
    setFaillureAdding([]);
    Api.post(
      Urls.CREATE_PROD_MANAGER,
      {
        upc: values?.upc,
        use_admin_image: values.use_admin,
        category: values?.category_value,
        format: values?.format,
        empty_shelf: values?.empty_shelf,

        price: values?.prix,
        price_reduction: values?.prix_red,
        alert_in_days: values?.alert,
        product_db_id: values?.bd?.id,
        location: values?.localisation,
        designation: values?.designation,
        active: values?.active,
        ...(!values?.empty_shelf && !values.no_date && { expiration_date: values.expiration_date ? values?.expiration_date : "" }),
        private_product_image: values?.image,
      },
      config
    )
      .then((res) => {
        setSuccess(t("forms.success.add"));
        setFaillureAdding(res?.data?.result?.failure_product_db_list);
        setSuccessAdding(res?.data?.result?.success_product_db_list);
        setLoadingSubmit(false);
        setProgressFile(0);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      })
      .catch((err) => {
        handleError(err?.response?.data);
        setLoadingSubmit(false);
        setProgressFile(0);
      });
  };
  //HANDLE ERROR
  function handleError(error) {
    setError({
      upc: error?.upc,
      format: error?.format,
      department: error?.department,
      sub_department: error?.sub_department,
      price: error?.price,
      price_reduction: error?.price_reduction,
      alert_in_days: error?.alert_in_days,
      supplier: error?.supplier,
      note: error?.note,
      product_db_id: error?.product_db_id,
      location: error?.location,
      designation: error?.designation,
      product_image: error?.product_image,
    });
  }

  const FileUploadTextField = ({ field, form, ...props }) => {
    const handleFileChange = (event) => {
      form.setFieldValue(field.name, event.currentTarget.files[0]);
    };

    return (
      <TextField
        {...props}
        id={field.name}
        type="text"
        value={field.value ? field.value.name : ""}
        InputProps={{
          startAdornment: (
            <label htmlFor={field.name}>
              <input type="file" id={field.name} name={field.name} style={{ display: "none" }} onChange={handleFileChange} accept="image/*" />
              <Button sx={{ borderRadius: "0px", width: "50px", marginLeft: "-10px", borderRight: "1px solid #dedfe4" }} component="span" type="file">
                <UploadFile />
              </Button>
            </label>
          ),
        }}
      />
    );
  };

  return (
    <Box>
      <Formik initialValues={initial_values} validationSchema={validationSchema} enableReinitialize onSubmit={handleSubmit}>
        {({ errors, values, setFieldValue }) => (
          <Form>
            <Box maxWidth="500px" display="flex" flexDirection="column" gap="16px">
              <Box>
                <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                  <Box flex={1}>
                    <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                      UPC / PLU
                    </Typography>
                  </Box>
                  <Box>
                    <Field name="upc" type="input" placeholder="UPC" as={TextField} />
                    <ErrorMessage name="upc" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                  <Box flex={1}>
                    <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                      {t("forms.labels.designation")}
                    </Typography>
                  </Box>
                  <Box>
                    <Field name="designation" type="input" placeholder={t("forms.labels.designation")} as={TextField} />
                    <ErrorMessage name="designation" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                  <Box flex={1}>
                    <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                      {t("forms.labels.format")}
                    </Typography>
                  </Box>
                  <Box>
                    <Field name="format" type="input" placeholder={t("forms.labels.format")} as={TextField} />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" type="input" alignItems="center" gap="24px">
                  <Box flex={1}>
                    <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                      {t("forms.labels.localisation")}
                    </Typography>
                  </Box>
                  <Box>
                    <Field name="localisation" placeholder={t("forms.labels.localisation")} as={TextField} />
                    <ErrorMessage name="localisation" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                <Box flex={1}>
                  <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                    {t("forms.labels.category")}
                  </Typography>
                </Box>
                <Box>
                  <Autocomplete
                    sx={{ minWidth: "262px", maxWidth: "263px" }}
                    options={categories}
                    disableClearable
                    value={values.category}
                    getOptionLabel={(e) => e?.name}
                    isOptionEqualToValue={(option, value) => option?.value === value?.value}
                    onChange={(event, value) => {
                      if (value !== null) {
                        setFieldValue("category_value", value?.value);
                        setFieldValue("category", value);
                      } else {
                        setFieldValue("category", null);
                        setFieldValue("category_value", "");
                      }
                    }}
                    renderInput={(params) => <TextField fullWidth {...params} name="autocomplete" placeholder={t("forms.labels.category")} />}
                  />
                </Box>
                <ErrorMessage name="category_value" component={FormikErrorText} color={colors.main} width="250px" />
              </Box>

              <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                <Box flex={1}>
                  <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                    {t("forms.labels.expiration_date")}
                  </Typography>
                </Box>
                <Box flex={1.2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="yyyy-MM-dd"
                      value={values.expiration_date}
                      minDate={new Date()}
                      disabled={noDispo}
                      InputProps={{ sx: { "& .MuiSvgIcon-root": { color: colors?.main, height: "30px", width: "30px" } } }}
                      onChange={(value) => {
                        setFieldValue("expiration_date", moment(value).format("YYYY-MM-DD"));
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <ErrorMessage name="expiration_date" component={FormikErrorText} color={colors.main} width="250px" />
                </Box>
              </Box>
              <Box>
                <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                  <Box flex={1}>
                    <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                      {t("forms.labels.image")}
                    </Typography>
                  </Box>
                  <Box flex={1.2}>
                    <Field autoComplete="off" name="image" placeholder="Choose a file" id="icon" component={FileUploadTextField} fullWidth />
                    <ErrorMessage name="image" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" type="input" alignItems="center" gap="24px">
                  <Box flex={1}>
                    <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                      {t("forms.labels.prix")}
                    </Typography>
                  </Box>
                  <Box>
                    <Field name="prix" placeholder={t("forms.labels.prix")} as={TextField} />
                    <ErrorMessage name="prix" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" type="input" alignItems="center" gap="24px">
                  <Box flex={1}>
                    <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                      {t("forms.labels.prix_red")}
                    </Typography>
                  </Box>
                  <Box>
                    <Field name="prix_red" type="input" placeholder={t("forms.labels.prix_red")} as={TextField} />
                    <ErrorMessage name="prix_red" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                </Box>
              </Box>

              <Box>
                <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                  <Box flex={1}>
                    <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                      {t("forms.labels.alert")}
                    </Typography>
                  </Box>
                  <Box>
                    <Field type="number" name="alert" placeholder={t("forms.labels.alert")} as={TextField} />
                    <ErrorMessage name="alert" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                </Box>
              </Box>

              <FormControlLabel
                sx={{
                  fontWeight: "600",
                  color: colors?.main,
                  fontSize: "18px",
                }}
                control={
                  <Checkbox
                    checked={values?.active}
                    value={values?.active}
                    onChange={(e) => {
                      setFieldValue("active", e.target?.checked);
                    }}
                  />
                }
                label="ACTIF"
              />
              <FormControlLabel
                sx={{
                  fontWeight: "600",
                  color: colors?.main,
                  fontSize: "18px",
                }}
                control={
                  <Checkbox
                    checked={values?.no_date}
                    value={values?.no_date}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFieldValue("expiration_date", null);
                        setFieldValue("empty_shelf", false);
                      }
                      setNoDispo(e.target.checked);
                      setFieldValue("no_date", e.target.checked);
                    }}
                  />
                }
                label={t("forms.labels.date_non_disponible")}
              />
              <FormControlLabel
                sx={{
                  fontWeight: "600",
                  color: colors?.main,
                  fontSize: "18px",
                }}
                control={
                  <Checkbox
                    checked={values?.empty_shelf}
                    value={values?.empty_shelf}
                    onChange={(e) => {
                      setFieldValue("empty_shelf", e.target.checked);

                      if (e.target.checked) {
                        setFieldValue("no_date", false);
                      }
                      setNoDispo(e.target.checked);
                      setFieldValue("expiration_date", null);
                    }}
                  />
                }
                label={t("forms.labels.tablette_vide")}
              />
              <FormControlLabel
                sx={{
                  fontWeight: "600",
                  color: colors?.main,
                  fontSize: "18px",
                }}
                control={
                  <Checkbox
                    checked={values?.use_admin}
                    value={values?.use_admin}
                    onChange={(e) => {
                      setFieldValue("use_admin", e.target?.checked);
                    }}
                  />
                }
                label="USE ADMIN PIC"
              />
              {success && <Alert severity="success">{success}</Alert>}
              {error && (
                <Alert severity="error">
                  <Box>
                    <List>
                      {error?.upc && <ListItem>UPC: {error?.upc}</ListItem>}
                      {error?.format && (
                        <ListItem>
                          {t("forms.labels.format")} : {error?.format}
                        </ListItem>
                      )}
                      {error?.designation && (
                        <ListItem>
                          {t("forms.labels.designation")} : {error?.designation}
                        </ListItem>
                      )}
                      {error?.supplier && (
                        <ListItem>
                          {t("forms.labels.fournisseur")} : {error?.supplier}
                        </ListItem>
                      )}
                      {error?.product_image && <ListItem>IMAGE : {error?.product_image}</ListItem>}
                      {error?.price && (
                        <ListItem>
                          {t("forms.labels.prix")} : {error?.price}
                        </ListItem>
                      )}
                      {error?.price_reduction && (
                        <ListItem>
                          {t("forms.labels.prix_red")} : {error?.price_reduction}
                        </ListItem>
                      )}
                      {error?.product_db_id && (
                        <ListItem>
                          {t("forms.labels.bd")} : {error?.product_db_id}
                        </ListItem>
                      )}
                      {error?.alert_in_days && (
                        <ListItem>
                          {t("forms.labels.alert")} : {error?.alert_in_days}
                        </ListItem>
                      )}
                      {error?.department && (
                        <ListItem>
                          {t("forms.labels.departement")} : {error?.department}
                        </ListItem>
                      )}
                      {error?.sub_department && (
                        <ListItem>
                          {t("forms.labels.sous-dep")} : {error?.sub_department}
                        </ListItem>
                      )}
                    </List>
                  </Box>
                </Alert>
              )}
              {successAdding?.length > 0 && (
                <Box padding="16px" borderRadius="8px" backgroundColor="rgba(144,238,144,0.7)" maxWidth="500px" display="flex" flexDirection="column" gap="10px">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="text_md_black">
                      <strong>{t("forms.success.add")}</strong>
                    </Typography>
                    <IconButton onClick={() => setSuccessAdding([])}>
                      <CloseOutlined sx={{ color: "black" }} />
                    </IconButton>
                  </Box>
                  {successAdding?.map((success, index) => (
                    <Box key={index} display="flex" gap="8px" flexWrap="wrap">
                      <Typography variant="text_sm_black">
                        <strong>- {t("forms.labels.bd")}</strong> : {success.name}{" "}
                      </Typography>
                      <Typography variant="text_sm_black">
                        <strong>- IS MASTER</strong> : {success.is_master ? "YES" : "NO"}{" "}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
              {faillureAdding?.length > 0 && (
                <Box padding="16px" borderRadius="8px" backgroundColor={colors?.main} maxWidth="500px" display="flex" flexDirection="column" gap="10px">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="text_md_white">
                      <strong>{t("forms.faillure.failed")}</strong>
                    </Typography>
                    <IconButton onClick={() => setFaillureAdding([])}>
                      <CloseOutlined sx={{ color: "white" }} />
                    </IconButton>
                  </Box>
                  {faillureAdding?.map((failed, index) => (
                    <Box key={index} display="flex" gap="8px" flexWrap="wrap">
                      <Typography variant="text_sm_white">
                        <strong>- {t("forms.labels.bd")}</strong> : {failed.name}{" "}
                      </Typography>
                      <Typography variant="text_sm_white">
                        <strong>- IS MASTER</strong> : {failed.is_master ? "YES" : "NO"}{" "}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
              <Box display="flex" gap="10px" alignItems="center">
                <Button disabled={loadingSubmit} type="submit" variant="primary">
                  {t("forms.submit.save")}
                </Button>
                {progressFile !== 0 && (
                  <Box display="flex" alignItems="center" gap="5px">
                    <Loading color={colors?.main} />
                    <Typography color={colors?.main}>{progressFile}%</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default AjoutProduitM;
