import { Box, Button, IconButton, Typography } from "@mui/material";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useEffect, useState } from "react";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { toast, ToastContainer } from "react-toastify";
import imagePlaceholder from "../../../assets/placeholder-4-300x200.png";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/colors";
import moment from "moment";
import Loading from "../../../components/ui/Loading";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
const ProduitsEmptyShelfListeEMP = () => {
  const { user } = useAuthContext();
  const access_token = user?.access;
  const [produits, setProduits] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [modification, setModification] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get("page")) || 1;
  const [response, setResponse] = useState({});

  useEffect(() => {
    if (page === 1) {
      setSearchParams({ page: 1 });
    }
    setLoading(true);
    Api.get(Urls.GetEmptyShelf, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        offset: page === 1 ? 0 : (page - 1) * 25,
        limit: 25,
      },
    })
      .then((res) => {
        setProduits(res?.data?.results);
        setResponse(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.details);
        setLoading(false);
      });
  }, [page]);

  const Pagination = () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="end">
        {response?.previous && (
          <IconButton
            onClick={() => {
              setSearchParams({ page: page - 1 });
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <ArrowLeft sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
        <Box>
          <Typography variant="text_md_black">{response?.count}</Typography>
        </Box>
        {response?.next && (
          <IconButton
            onClick={() => {
              setSearchParams({ page: page + 1 });
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <ArrowRight sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
      </Box>
    );
  };

  const traiterNoProd = (id) => {
    Api.post(
      Urls.TreatNoProductRecall(id),
      {},
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(t("forms.success.update"));
        setModification(!modification);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.details);
      });
  };

  const calculateDaysDiff = (date2) => {
    const date1obj = new Date();
    const date2obj = new Date(date2);
    const timeDiff = date2obj - date1obj;
    const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return dayDiff;
  };
  return (
    <Box>
      <ToastContainer />
      {loading && <Loading />}
      {!loading && (
        <Box display="flex" flexDirection="column" gap="24px">
          {produits?.map((prod) => (
            <>
              {!prod?.no_corresponding_product && (
                <Box
                  key={prod?.upc}
                  onClick={() => {
                    navigate("/employee/gestion-des-tablettes/liste/" + prod?.upc);
                  }}
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  gap="20px"
                  alignItems="center"
                  backgroundColor="rgba(211,211,211 , 0.5)"
                  borderRadius="16px"
                  padding="10px"
                >
                  <Box sx={{ flex: "1" }}>
                    <img style={{ height: "auto", width: "auto", aspectRatio: "1/1" }} src={prod?.use_admin_image ? (prod.product_image?.product_image ? process.env.REACT_APP_IMAGE_URL + prod.product_image?.product_image : imagePlaceholder) : prod.private_product_image ? process.env.REACT_APP_IMAGE_URL + prod.private_product_image : imagePlaceholder} alt="produit" />
                  </Box>
                  <Box flex={1} sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                      UPC: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.upc}</Typography>
                    </Typography>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                      <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.designation}</Typography>
                    </Typography>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                      {t("forms.labels.format")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.format}</Typography>
                    </Typography>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                      {t("forms.labels.localisation")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.location ? prod?.location : "-"}</Typography>
                    </Typography>
                    <Box>
                      <Typography sx={{ fontSize: "16px", fontWeight: "600", color: "black" }}>{t("forms.labels.expiration_date")}:</Typography>
                      <Typography sx={{ fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.expiration_date ? moment(prod?.expiration_date).format("MMMM DD, yyyy") : "-"}</Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>{t("expire_d")}:</Typography>
                      <Typography sx={{ fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.expiration_date ? calculateDaysDiff(prod?.expiration_date) + " " + t("days") : "-"}</Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              {prod?.no_corresponding_product && (
                <Box sx={{ cursor: "pointer" }} display="flex" gap="20px" alignItems="center" backgroundColor="rgba(211,211,211 , 0.5)" borderRadius="16px" padding="10px">
                  <Box sx={{ flex: "1" }}>
                    <img style={{ height: "auto", width: "auto", aspectRatio: "1/1" }} src={prod?.product?.use_admin_image ? (prod.product?.product_image?.product_image ? process.env.REACT_APP_IMAGE_URL + prod.product?.product_image?.product_image : imagePlaceholder) : prod.product?.private_product_image ? process.env.REACT_APP_IMAGE_URL + prod.product?.private_product_image : imagePlaceholder} alt="produit" />
                  </Box>
                  <Box flex={1} sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{t("no_product")}</Typography>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                      UPC: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>-</Typography>
                    </Typography>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                      {t("forms.labels.designation")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>-</Typography>
                    </Typography>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                      {t("forms.labels.format")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>-</Typography>
                    </Typography>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                      {t("forms.labels.localisation")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.product?.location ? prod?.product?.location : "-"}</Typography>
                    </Typography>

                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                      {t("forms.labels.note")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.note}</Typography>
                    </Typography>
                    <Box>
                      <Button onClick={() => traiterNoProd(prod?.id)} variant="primary">
                        {t("traiter")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
            </>
          ))}
          <Box marginBottom="24px">
            <Pagination />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default ProduitsEmptyShelfListeEMP;
