import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, tableCellClasses } from "@mui/material";
import styled from "@emotion/styled";
import React, { useState } from "react";
import StyledCellComponent from "./StyledCellComponent";
import { colors } from "../../theme/colors";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    paddingTop: "8px",
    paddingBottom: "8px",
    letterSpacing: "1px",
    color: colors.main,
    fontSize: 16,
    fontWeight: 500,
    background: "lightgray",
  },
  [`&.${tableCellClasses.body}`]: {
    background: "white",
    fontSize: 16,
    color: `rgba(75,70,92,0.8)`,
    fontWeight: 600,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },

  cursor: "pointer",
}));

const StyledTable = styled(Table)({
  borderRadius: "6px",
});
const StyledTableContainer = styled(TableContainer)({
  borderRadius: "6px",
  border: "1px solid lightgray",
});
const StyledTableComponent = ({ sorting, setSorting, data, columns, Buttons, pagination, setData, action }) => {
  const [isSorted, setIsSorted] = useState(false);
  //sort selector
  const sortSelector = (type, name, direction) => {
    setSorting({
      name: name,
      direction: direction,
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap="24px">
      <StyledTableContainer>
        <StyledTable aria-label="customized table">
          <TableHead>
            <TableRow>
              {columns?.map((col, index) => (
                <React.Fragment key={index}>
                  {index > 0 && (
                    <StyledTableCell width={col.width} align={col.align}>
                      <Box display="flex" gap="5px" alignItems="center">
                        <Box>{col.name}</Box>
                        <Box sx={{ display: "flex" }}>
                          <IconButton sx={{ height: "30px", width: "30px", padding: "3px !important" }} onClick={() => sortSelector(col?.type, col?.original_name, "up")}>
                            <ArrowUpward sx={{ fontSize: "20px", color: `${colors.main}` }} />
                          </IconButton>
                          <IconButton sx={{ height: "30px", width: "30px", padding: "3px !important" }} onClick={() => sortSelector(col?.type, col?.original_name, "down")}>
                            <ArrowDownward sx={{ fontSize: "20px", color: `${colors.main}` }} />
                          </IconButton>
                        </Box>
                      </Box>
                    </StyledTableCell>
                  )}
                </React.Fragment>
              ))}
              {action !== "false" && (
                <>
                  <StyledTableCell key={columns?.length + 1} width="20%" align="left">
                    ACTION
                  </StyledTableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((ban, index) => (
              <React.Fragment key={index}>
                <StyledTableRow key={ban?.id}>
                  {columns?.map((column, index) => (
                    <StyledCellComponent type={column?.type} key={index} object={ban} index={index} />
                  ))}
                  <StyledTableCell>
                    <Buttons id={ban?.id} column={ban} />
                  </StyledTableCell>
                </StyledTableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
      {data?.length === 0 && (
        <Box textAlign="center">
          <Typography fontSize="30">-</Typography>
        </Box>
      )}
      {pagination}
    </Box>
  );
};
export default StyledTableComponent;
