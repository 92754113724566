import { Alert, Box, Button, List, ListItem, TextField, Typography } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/colors";
import * as yup from "yup";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Urls } from "../../../api/Urls";

import Api from "../../../api/Api";
import { useLogin } from "../../../hooks/useLogin";
import Loading from "../../../components/ui/Loading";
import { useParams } from "react-router-dom";
const AjoutProduits = () => {
  const [error, setError] = useState("");
  const { user } = useAuthContext();
  const [success, setSuccess] = useState("");
  const { id } = useParams();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const access_token = user?.access;
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [initial_values, setInitialValues] = useState({
    upc: "",
    format: "",
    designation: "",
    note: "",
  });
  //validation schema
  const validationSchema = yup.object({
    upc: yup?.string().required(t("validation_required")),
    designation: yup?.string().required(t("validation_required")),
    note: yup?.string().required(t("validation_required")),
  });
  useEffect(() => {
    Api?.get(Urls?.GetProductForAlertLauncherByID(id), {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setInitialValues({
          upc: res?.data?.upc,
          format: res?.data?.format,
          designation: res?.data?.designation,
          expiration_date: null,
          note: "",
        });
        setLoading(false);
      })
      .catch((err) => {
        setError(err?.response?.data?.details);
        setLoading(false);
      });
  }, []);

  //HANDLE SBUMIT CREATE PRODUCT RECALL
  const handleSubmit = (values) => {
    setError(null);
    setLoadingSubmit(true);
    Api.post(
      Urls.UpdateRecallProductByID,
      {
        note: values?.note,
        expiration_date: values?.expiration_date,
        upc: values?.upc,
        designation: values?.designation,
        format: values?.format,
      },
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setSuccess(t("forms.success.update"));
        setLoadingSubmit(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      })
      .catch((err) => {
        handleError(err?.response?.data);
        setLoadingSubmit(false);
      });
  };
  //HANDLE ERROR
  function handleError(error) {
    setError({
      upc: error?.upc,
      format: error?.format,
      designation: error?.designation,
    });
  }
  return (
    <Box>
      {loading && <Loading />}
      {!loading && (
        <Formik initialValues={initial_values} validationSchema={validationSchema} enableReinitialize onSubmit={handleSubmit}>
          {({ errors, values, setFieldValue }) => (
            <Form>
              <Box maxWidth="500px" display="flex" flexDirection="column" gap="16px">
                <Box>
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        UPC / PLU
                      </Typography>
                    </Box>
                    <Box>
                      <Field disabled={true} name="upc" type="input" placeholder="UPC" as={TextField} />
                      <ErrorMessage name="upc" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                  </Box>
                </Box>

                <Box>
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.format")}
                      </Typography>
                    </Box>
                    <Box>
                      <Field disabled={true} name="format" type="input" placeholder={t("forms.labels.format")} as={TextField} />
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.designation")}
                      </Typography>
                    </Box>
                    <Box>
                      <Field multiline rows={2} disabled={true} name="designation" type="input" placeholder={t("forms.labels.designation")} as={TextField} />
                      <ErrorMessage name="designation" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                  </Box>
                </Box>

                <Box>
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="start" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.note")}
                      </Typography>
                    </Box>
                    <Box>
                      <TextField
                        fullWidth
                        sx={{ width: { xs: "300px", sm: "400px", md: "600px", lg: "600px" } }}
                        disabled={false}
                        value={values?.note}
                        columns={8}
                        rows={10}
                        multiline
                        onChange={(e) => {
                          setFieldValue("note", e.target?.value);
                        }}
                        type="input"
                        placeholder={t("forms.labels.note")}
                      />
                      <ErrorMessage name="note" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                  </Box>
                </Box>

                {success && <Alert severity="success">{success}</Alert>}
                {error && (
                  <Alert severity="error">
                    <Box>
                      <List>
                        {error?.upc && <ListItem>UPC: {error?.upc}</ListItem>}
                        {error?.format && (
                          <ListItem>
                            {t("forms.labels.format")} : {error?.format}
                          </ListItem>
                        )}
                        {error?.designation && (
                          <ListItem>
                            {t("forms.labels.designation")} : {error?.designation}
                          </ListItem>
                        )}
                        {error?.note && (
                          <ListItem>
                            {t("forms.labels.designation")} : {error?.designation}
                          </ListItem>
                        )}
                      </List>
                    </Box>
                  </Alert>
                )}
                {/*add hna*/}
                <Box display="flex" gap="10px" alignItems="center">
                  <Button disabled={loadingSubmit} type="submit" variant="primary">
                    {t("forms.submit.save")}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};
export default AjoutProduits;
