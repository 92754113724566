import { Alert, Autocomplete, Box, CircularProgress, IconButton, TextField, Typography } from "@mui/material";
import StyledTableComponent from "../../../components/ui/StyledTableComponent";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { action_types_en, action_types_es, action_types_fr, action_types_list_en, action_types_list_es, action_types_list_fr } from "../../../assets/data/ActionTypes";
import { Urls } from "../../../api/Urls";
import { columns_actions_fr, columns_actions_en, columns_actions_es } from "../../../columns/actionColumns";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../theme/colors";
import { ArrowLeft, ArrowRight, Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import debounce from "debounce";
import { categories_list_en, categories_list_es, categories_list_fr } from "../../../assets/data/categories";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
const MarchandActions = () => {
  const { user } = useAuthContext();
  let access_token = user?.access;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const [response, setResponse] = useState();
  const [error, setError] = useState("");
  const [actionType, setActionType] = useState({ name: "", des: "" });
  const [date, setDate] = useState(null);
  const [columns, setColumns] = useState([]);
  const [category, setCategory] = useState({ value: "", name: "" });
  const lang = localStorage.getItem("i18nextLng");
  const [sorting, setSorting] = useState({
    name: "",
    direction: "",
  });
  const categories = lang === "fr" ? categories_list_fr : lang === "en" ? categories_list_en : categories_list_es;
  let action_types_list = lang === "fr" ? action_types_list_fr : lang === "en" ? action_types_list_en : lang === "es" ? action_types_list_es : {};
  let action_types = lang === "fr" ? action_types_fr : lang === "en" ? action_types_en : lang === "es" ? action_types_es : {};
  useEffect(() => {
    setColumns(lang === "fr" ? columns_actions_fr : lang === "en" ? columns_actions_en : lang === "es" ? columns_actions_es : []);
  }, []);
  const [search, setSearch] = useState("");
  const [searchQ, setSearchQ] = useState("");
  useEffect(() => {
    setLoading(true);
    Api.get(Urls?.GET_ACTIONS_STORE_MANAGER, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        offset: 0,
        limit: 25,
        ...(actionType?.des && { action: actionType?.des }),
        ...(category?.value && { category: category?.value }),
        ...(searchQ && { q: searchQ }),
        ...(date && { creation_date: date }),
        ...(sorting?.name && { order_by: sorting?.name }),
        ...(sorting?.direction && { order_direction: sorting?.direction === "up" ? "asc" : "desc" }),
      },
    })

      .then((res) => {
        setData((data) => {
          let array = [];
          let d = res?.data?.results;
          for (let index = 0; index < d.length; index++) {
            let element = d[index];
            element = { id: element?.id, product_image: element?.product?.use_admin_image ? process.env.REACT_APP_IMAGE_URL + element?.product?.product_image?.product_image : process.env.REACT_APP_IMAGE_URL + element?.product?.private_product_image, designation: element?.product?.designation, format: element?.product?.format, created_at: element?.created_at, treated_by: element?.treated_by, action: action_types[`${element?.action}`] };
            array.push(element);
          }
          return array;
        });
        setResponse(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err?.response?.data?.details);

        setLoading(false);
      });
  }, [actionType, category, searchQ, date, sorting]);

  const handlePagination = (url) => {
    Api.get(url, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })

      .then((res) => {
        setData((data) => {
          let array = [];
          let d = res?.data?.results;
          for (let index = 0; index < d.length; index++) {
            let element = d[index];
            element = { id: element?.id, product_image: element?.product?.use_admin_image ? process.env.REACT_APP_IMAGE_URL + element?.product?.product_image?.product_image : process.env.REACT_APP_IMAGE_URL + element?.product?.private_product_image, designation: element?.product?.designation, format: element?.product?.format, created_at: element?.created_at, treated_by: element?.treated_by, action: action_types[`${element?.action}`] };
            array.push(element);
          }
          return array;
        });
        setResponse(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err?.response?.data?.details);

        setLoading(false);
      });
  };
  //debounced search
  const debouncedSearch = React.useMemo(() => {
    return debounce((search) => {
      setSearchQ(search);
    }, 700);
  }, []);
  useEffect(() => {
    debouncedSearch(search);
  }, [search]);
  const Buttons = ({ id }) => {
    return (
      <>
        <IconButton onClick={() => navigate("/marchand/gestion-des-suivi/liste/" + id)}>
          <ReadMoreIcon sx={{ color: `${colors.main}` }} />
        </IconButton>
      </>
    );
  };
  const Pagination = () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="end">
        {response?.previous && (
          <IconButton onClick={() => handlePagination(response?.previous)}>
            <ArrowLeft sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
        <Box>
          <Typography variant="text_md_black">{response?.count}</Typography>
        </Box>
        {response?.next && (
          <IconButton onClick={() => handlePagination(response?.next)}>
            <ArrowRight sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
      </Box>
    );
  };

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box display="flex" gap="10px" maxWidth="500px">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
            {t("forms.labels.creation_date")}
          </Typography>{" "}
        </Box>
        <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              inputFormat="yyyy-MM-dd"
              value={date}
              maxDate={new Date()}
              InputProps={{ sx: { "& .MuiSvgIcon-root": { color: colors?.main, height: "30px", width: "30px" } } }}
              onChange={(value) => {
                setDate(moment(value).format("YYYY-MM-DD"));
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
        <IconButton onClick={() => setDate(null)}>
          <Close />
        </IconButton>
      </Box>
      <Box display="flex" gap="10px" alignItems="center" maxWidth="500px">
        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
          {t("forms.labels.search")}
        </Typography>
        <TextField placeholder={t("forms.labels.search")} type="input" value={search} onChange={(e) => setSearch(e.target.value)} />
      </Box>
      <Box sx={{ width: "100%", display: "flex", alignItems: "center", gap: "10px" }}>
        <Box>
          <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
            TYPE
          </Typography>{" "}
        </Box>
        <Autocomplete
          sx={{ maxWidth: "600px" }}
          options={action_types_list}
          value={actionType}
          getOptionLabel={(e) => e?.name}
          isOptionEqualToValue={(option, value) => option?.des === value?.des}
          onChange={(event, value) => {
            if (value !== null) {
              setActionType(value);
            } else setActionType({ name: "", des: "" });
          }}
          renderInput={(params) => <TextField {...params} name="autocomplete" placeholder={action_types_list[0].name} />}
        />
      </Box>
      <Box sx={{ width: "100%", display: "flex", alignItems: "center", gap: "10px" }}>
        <Box>
          <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
            {t("forms.labels.category")}
          </Typography>
        </Box>
        <Box>
          <Autocomplete
            sx={{ minWidth: "262px", maxWidth: "263px" }}
            options={categories}
            value={category}
            getOptionLabel={(e) => e?.name}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            onChange={(event, value) => {
              if (value !== null) {
                setCategory(value);
              } else {
                setCategory(null);
              }
            }}
            renderInput={(params) => <TextField fullWidth {...params} name="autocomplete" placeholder={t("forms.labels.category")} />}
          />
        </Box>
      </Box>
      {error && !loading && <Alert severity="error">{error}</Alert>}
      {!error && !loading && <StyledTableComponent sorting={sorting} setSorting={setSorting} action={"true"} setData={setData} data={data} columns={columns} Buttons={Buttons} pagination={<Pagination />} />}
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};
export default MarchandActions;
