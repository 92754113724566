import { Alert, Box, Button, List, ListItem, TextField, Typography } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/colors";
import * as yup from "yup";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Urls } from "../../../api/Urls";

import Api from "../../../api/Api";
import Loading from "../../../components/ui/Loading";
const Alerte = () => {
  const [error, setError] = useState("");
  const { user } = useAuthContext();
  const [success, setSuccess] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const access_token = user?.access;
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [initial_values, setInitialValues] = useState({
    expiration_date_alert_email_1: "",
    expiration_date_alert_email_2: "",
    recall_notification_email_1: "",
    recall_notification_email_2: "",
    update_notification_email_1: "",
    update_notification_email_2: "",
    not_available_product_alert_email_1: "",
    not_available_product_alert_email_2: "",
    product_deactivation_request_email: "",
  });
  //validation schema
  const validationSchema = yup.object({
    expiration_date_alert_email_1: yup?.string().email(t("forms.validation.email")).nullable(),
    expiration_date_alert_email_2: yup?.string().email(t("forms.validation.email")).nullable(),

    recall_notification_email_1: yup?.string().email(t("forms.validation.email")).nullable(),
    recall_notification_email_2: yup?.string().email(t("forms.validation.email")).nullable(),

    update_notification_email_1: yup?.string().email(t("forms.validation.email")).nullable(),
    update_notification_email_2: yup?.string().email(t("forms.validation.email")).nullable(),

    not_available_product_alert_email_1: yup?.string().email(t("forms.validation.email")).nullable(),
    not_available_product_alert_email_2: yup?.string().email(t("forms.validation.email")).nullable(),
  });
  useEffect(() => {
    Api?.get(Urls?.GET_STORE_MANAGER_PREFERENCES, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setInitialValues({
          product_deactivation_request_email: res?.data?.product_deactivation_request_email ? res?.data?.product_deactivation_request_email : "",
          expiration_date_alert_email_1: res?.data?.expiration_date_alert_email_1 ? res?.data?.expiration_date_alert_email_1 : "",
          expiration_date_alert_email_2: res?.data?.expiration_date_alert_email_2 ? res?.data?.expiration_date_alert_email_2 : "",
          recall_notification_email_1: res?.data?.recall_notification_email_1 ? res?.data?.recall_notification_email_1 : "",
          recall_notification_email_2: res?.data?.recall_notification_email_2 ? res?.data?.recall_notification_email_2 : "",
          update_notification_email_1: res?.data?.update_notification_email_1 ? res?.data?.update_notification_email_1 : "",
          update_notification_email_2: res?.data?.update_notification_email_2 ? res?.data?.update_notification_email_2 : "",
          not_available_product_alert_email_1: res?.data?.not_available_product_alert_email_1 ? res?.data?.not_available_product_alert_email_1 : "",
          not_available_product_alert_email_2: res?.data?.not_available_product_alert_email_2 ? res?.data?.not_available_product_alert_email_2 : "",
        });
        setLoading(false);
      })
      .catch((err) => {
        setError(err?.response?.data?.details);
        setLoading(false);
      });
  }, []);

  //HANDLE SBUMIT CREATE PRODUCT RECALL
  const handleSubmit = (values) => {
    setError(null);
    setLoadingSubmit(true);
    Api.patch(
      Urls.UPDATE_STORE_MANAGER_PREFS,
      {
        ...(values.product_deactivation_request_email && { product_deactivation_request_email: values.product_deactivation_request_email }),
        ...(values.expiration_date_alert_email_1 && { expiration_date_alert_email_1: values.expiration_date_alert_email_1 }),
        ...(values.expiration_date_alert_email_2 && { expiration_date_alert_email_2: values.expiration_date_alert_email_2 }),
        ...(values.recall_notification_email_1 && { recall_notification_email_1: values.recall_notification_email_1 }),
        ...(values.recall_notification_email_2 && { recall_notification_email_2: values.recall_notification_email_2 }),
        ...(values.update_notification_email_1 && { update_notification_email_1: values.update_notification_email_1 }),
        ...(values.update_notification_email_2 && { update_notification_email_2: values.update_notification_email_2 }),
        ...(values.not_available_product_alert_email_1 && { not_available_product_alert_email_1: values.not_available_product_alert_email_1 }),
        ...(values.not_available_product_alert_email_2 && { not_available_product_alert_email_2: values.not_available_product_alert_email_2 }),
      },
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setSuccess(t("forms.success.update"));
        setLoadingSubmit(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      })
      .catch((err) => {
        handleError(err?.response?.data);
        setLoadingSubmit(false);
      });
  };
  //HANDLE ERROR
  function handleError(error) {
    setError(error?.details);
  }
  return (
    <Box>
      {loading && <Loading />}
      {!loading && (
        <Formik initialValues={initial_values} validationSchema={validationSchema} enableReinitialize onSubmit={handleSubmit}>
          {() => (
            <Form>
              <Box maxWidth="500px" display="flex" flexDirection="column" gap="16px">
                <Box>
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.alerte_30")}
                      </Typography>
                    </Box>

                    <Box>
                      <Box>
                        <Field name="expiration_date_alert_email_1" type="input" placeholder="EMAIL" as={TextField} />
                        <ErrorMessage name="expiration_date_alert_email_1" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                      <Box>
                        <Field name="expiration_date_alert_email_2" type="input" placeholder="EMAIL" as={TextField} />
                        <ErrorMessage name="expiration_date_alert_email_2" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box>
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.alerte_rappel")}
                      </Typography>
                    </Box>
                    <Box>
                      <Box>
                        <Field name="recall_notification_email_1" type="input" placeholder="EMAIL" as={TextField} />
                        <ErrorMessage name="recall_notification_email_1" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                      <Box>
                        <Field name="recall_notification_email_2" type="input" placeholder="EMAIL" as={TextField} />
                        <ErrorMessage name="recall_notification_email_2" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.alerte_maj")}
                      </Typography>
                    </Box>
                    <Box>
                      <Box>
                        <Field name="update_notification_email_1" type="input" placeholder="EMAIL" as={TextField} />
                        <ErrorMessage name="update_notification_email_1" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                      <Box>
                        <Field name="update_notification_email_2" type="input" placeholder="EMAIL" as={TextField} />
                        <ErrorMessage name="update_notification_email_2" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box marginBottom="24px">
                    <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                      {t("forms.labels.alerte_non_dispo")}
                    </Typography>
                  </Box>
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.alerte_A_30")}
                      </Typography>
                    </Box>
                    <Box>
                      <Box>
                        <Field name="not_available_product_alert_email_1" type="input" placeholder="EMAIL" as={TextField} />
                        <ErrorMessage name="not_available_product_alert_email_1" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                      <Box>
                        <Field name="not_available_product_alert_email_2" type="input" placeholder="EMAIL" as={TextField} />
                        <ErrorMessage name="not_available_product_alert_email_2" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.alerte_retrait")}
                      </Typography>
                    </Box>
                    <Box>
                      <Box>
                        <Field name="product_deactivation_request_email" type="input" placeholder="EMAIL" as={TextField} />
                        <ErrorMessage name="product_deactivation_request_email" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {success && <Alert severity="success">{success}</Alert>}
                {error && (
                  <Alert severity="error">
                    <Box>
                      <List>{error?.details && <ListItem>{error?.details}</ListItem>}</List>
                    </Box>
                  </Alert>
                )}
                {/*add hna*/}
                <Box display="flex" gap="10px" alignItems="center">
                  <Button disabled={loadingSubmit} type="submit" variant="primary">
                    {t("forms.submit.save")}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};
export default Alerte;
