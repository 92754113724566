import { Alert, Box, CircularProgress, IconButton, Typography } from "@mui/material";
import StyledTableComponent from "../../../components/ui/StyledTableComponent";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import ReadMoreIcon from "@mui/icons-material/ReadMore";

import { Urls } from "../../../api/Urls";
import { columns_produit_en, columns_produit_es, columns_produit_fr } from "../../../columns/produitColumns";
import { useLocation, useNavigate } from "react-router-dom";
import { colors } from "../../../theme/colors";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
const ProduitsListe = () => {
  const { user } = useAuthContext();
  let access_token = user?.access;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const id = location?.state?.id;
  const [response, setResponse] = useState();
  const [error, setError] = useState("");
  const [isRecalled] = useState(false);
  const [sorting, setSorting] = useState({
    name: "",
    direction: "",
  });
  const [columns, setColumns] = useState([]);
  const lang = localStorage.getItem("i18nextLng");
  useEffect(() => {
    setColumns(lang === "fr" ? columns_produit_fr : lang === "en" ? columns_produit_en : lang === "es" ? columns_produit_es : []);
  }, []);
  useEffect(() => {
    setLoading(true);
    Api.get(Urls?.GET_PRODUITS_ALERT_LAUNCHER, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        offset: 0,
        limit: 25,
        ...(id && { upc: id }),
        ...(isRecalled && { is_recalled_notification: isRecalled }),
        ...(sorting?.name && { order_by: sorting?.name }),
        ...(sorting?.direction && { order_direction: sorting?.direction === "up" ? "asc" : "desc" }),
      },
    })

      .then((res) => {
        setData((data) => {
          let array = [];
          for (let index = 0; index < res?.data?.results.length; index++) {
            let element = res?.data?.results[index];
            element = { id: element?.id, product_image: element?.use_admin_image ? process.env.REACT_APP_IMAGE_URL + element.product_image?.product_image : process.env.REACT_APP_IMAGE_URL + element?.private_product_image, barcode: element?.upc, upc: element?.upc, designation: element?.designation, format: element?.format, ajout: element.updated_at };
            array.push(element);
          }
          return array;
        });
        setResponse(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err?.response?.data?.details);

        setLoading(false);
      });
  }, [sorting]);

  const handlePagination = (url) => {
    Api.get(url, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })

      .then((res) => {
        setData((data) => {
          let array = [];
          for (let index = 0; index < res?.data?.results.length; index++) {
            let element = res?.data?.results[index];
            element = { id: element?.id, product_image: element?.use_admin_image ? process.env.REACT_APP_IMAGE_URL + element.product_image?.product_image : process.env.REACT_APP_IMAGE_URL + element?.private_product_image, barcode: element?.upc, upc: element?.upc, designation: element?.designation, format: element?.format, ajout: element.updated_at };
            array.push(element);
          }
          return array;
        });
        setResponse(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err?.response?.data?.details);
        setLoading(false);
      });
  };

  const Buttons = ({ id }) => {
    return (
      <>
        <IconButton onClick={() => navigate("/lanceur-alerte/gestion-des-produits/liste/" + id)}>
          <ReadMoreIcon sx={{ color: `${colors.main}` }} />
        </IconButton>
      </>
    );
  };
  const Pagination = () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="end">
        {response?.previous && (
          <IconButton onClick={() => handlePagination(response?.previous)}>
            <ArrowLeft sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
        <Box>
          <Typography variant="text_md_black">{response?.count}</Typography>
        </Box>
        {response?.next && (
          <IconButton onClick={() => handlePagination(response?.next)}>
            <ArrowRight sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
      </Box>
    );
  };

  return (
    <Box display="flex" flexDirection="column" gap="24px">
      {error && !loading && <Alert severity="error">{error}</Alert>}
      {!error && !loading && <StyledTableComponent sorting={sorting} setSorting={setSorting} setData={setData} data={data} columns={columns} Buttons={Buttons} pagination={<Pagination />} />}
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};
export default ProduitsListe;
