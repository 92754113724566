import { Alert, Box, Button, CircularProgress, IconButton, TextField, Typography } from "@mui/material";
import StyledTableComponent from "../../../components/ui/StyledTableComponent";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { Urls } from "../../../api/Urls";
import { columns_images_en, columns_images_es, columns_images_fr } from "../../../columns/produitColumns";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../theme/colors";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import debounce from "debounce";
const ListImages = () => {
  const { user } = useAuthContext();
  let access_token = user?.access;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [response, setResponse] = useState();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [sorting, setSorting] = useState({
    name: "",
    direction: "",
  });
  const lang = localStorage.getItem("i18nextLng");
  const columns = lang === "fr" ? columns_images_fr : lang === "en" ? columns_images_en : columns_images_es;
  useEffect(() => {
    setLoading(true);
    Api.get(Urls?.GteProductsImages, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        offset: 0,
        limit: 25,
        q: searchQuery,
      },
    })

      .then((res) => {
        setData((data) => {
          let array = [];
          for (let index = 0; index < res?.data?.results.length; index++) {
            let element = res?.data?.results[index];
            element = { id: element?.id, product_image: process.env.REACT_APP_IMAGE_URL + element.product_image, barcode: element?.upc, upc: element?.upc, ajout: element.created_at };
            array.push(element);
          }
          return array;
        });
        setResponse(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err?.response?.data?.details);

        setLoading(false);
      });
  }, [searchQuery, sorting]);
  const associateImages = () => {
    Api.post(
      Urls?.AssociateImages,
      {},
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setSuccess(res?.data?.status);
        setTimeout(() => {
          setSuccess("");
        }, 2000);
      })
      .catch((err) => {
        setError(err?.response?.data?.detail);
      });
  };
  const handlePagination = (url) => {
    Api.get(url, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })

      .then((res) => {
        setData((data) => {
          let array = [];
          for (let index = 0; index < res?.data?.results.length; index++) {
            let element = res?.data?.results[index];
            element = { id: element?.id, product_image: process.env.REACT_APP_IMAGE_URL + element.product_image, barcode: element?.upc, upc: element?.upc, ajout: element.created_at };
            array.push(element);
          }
          return array;
        });
        setResponse(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err?.response?.data?.details);
        setLoading(false);
      });
  };
  //debounced search
  const debouncedSearch = React.useMemo(() => {
    return debounce((search) => {
      setSearchQuery(search);
    }, 700);
  }, []);
  useEffect(() => {
    debouncedSearch(search);
  }, [search]);
  const Buttons = ({ id, column }) => {
    return (
      <>
        <IconButton onClick={() => navigate("/admin/gestion-des-images/liste/" + id, { state: { url: column?.product_image } })}>
          <ReadMoreIcon sx={{ color: `${colors.main}` }} />
        </IconButton>
      </>
    );
  };
  const Pagination = () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="end">
        {response?.previous && (
          <IconButton onClick={() => handlePagination(response?.previous)}>
            <ArrowLeft sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
        <Box>
          <Typography variant="text_md_black">{response?.count}</Typography>
        </Box>
        {response?.next && (
          <IconButton onClick={() => handlePagination(response?.next)}>
            <ArrowRight sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
      </Box>
    );
  };

  return (
    <Box display="flex" flexDirection="column" gap="24px">
      <Box maxWidth="500px" display="flex" flexDirection="column" gap="10px">
        <Box>
          <Button
            variant="primary"
            onClick={() => {
              associateImages();
            }}
          >
            {t("forms.submit.associate")}
          </Button>
        </Box>
        <Box sx={{ width: "100%", display: "flex", alignItems: "center", gap: "10px" }}>
          <Box>
            <Typography sx={{ fontWeight: "600", color: colors.main }}>UPC / {t("forms.labels.designation")}</Typography>{" "}
          </Box>
          <TextField
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder="01564879 / biscuit"
          />
        </Box>
      </Box>

      {error && !loading && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      {!error && !loading && <StyledTableComponent sorting={sorting} setSorting={setSorting} setData={setData} data={data} columns={columns} Buttons={Buttons} pagination={<Pagination />} />}
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};
export default ListImages;
