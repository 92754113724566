import { Alert, Box, Button, Checkbox, FormControlLabel, List, ListItem, TextField, Typography } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/colors";
import * as yup from "yup";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import { useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Urls } from "../../../api/Urls";
import Api from "../../../api/Api";
import { categories_list_en, categories_list_es, categories_list_fr } from "../../../assets/data/categories";
const AjoutUtilisateurs = () => {
  const [error, setError] = useState("");
  const { user } = useAuthContext();
  const lang = localStorage?.getItem("i18nextLng");
  const [success, setSuccess] = useState("");
  const access_token = user?.access;
  const { t } = useTranslation();
  const [checkAllProd, setCheckAllProd] = useState(false);
  const initial_values = {
    first_name: "",
    last_name: "",
    visible: [],
    username: "",
    email: "",
    password: "",
  };
  let categories = lang === "fr" ? categories_list_fr : lang === "en" ? categories_list_en : categories_list_es;
  const validationSchema = yup.object({
    first_name: yup?.string().required(t("validation_required")),
    visible: !checkAllProd && yup?.array().min(1, t("validation_required")).required(t("validation_required")),
    last_name: yup?.string().required(t("validation_required")),
    email: yup?.string().email(t("forms.validation.email")).required(t("validation_required")),
    username: yup?.string().required(t("validation_required")),
    password: yup?.string().min(8, t("forms.validation.8_char_min")).required(t("validation_required")),
  });

  //HANDLE SBUMIT CREATE MANAGER
  const handleSubmit = (values) => {
    setError(null);
    let array = categories?.map((cat) => cat?.value);
    Api.post(
      Urls.ADD_EMPLOYEE,
      {
        store_employee_credentials: {
          username: values?.username,
          password: values?.password,
          email: values?.email,
        },
        store_employee_profile: {
          first_name: values?.first_name,
          last_name: values?.last_name,
          visible_categories: checkAllProd && values?.visible?.length === 0 ? array : checkAllProd && values?.visible?.length > 0 ? array.filter((x) => !values?.visible?.includes(x)) : values?.visible,
        },
      },
      {
        headers: { "Content-Type": "application/json", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setSuccess(t("forms.success.add"));
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      })
      .catch((err) => {
        handleError(err?.response?.data);
      });
  };
  //HANDLE ERROR
  function handleError(error) {
    setError({
      store_employee_credentials: {
        username: error?.store_employee_credentials?.username,
        password: error?.store_employee_credentials?.password,
        email: error?.store_employee_credentials?.email,
      },
      store_employee_profile: {
        first_name: error?.store_employee_profile?.first_name,
        last_name: error?.store_employee_profile?.last_name,
        visible_categories: error?.store_employee_profile?.visible_categories,
      },
    });
  }

  return (
    <Box>
      <Formik initialValues={initial_values} validationSchema={validationSchema} enableReinitialize onSubmit={handleSubmit}>
        {({ errors, values, setFieldValue }) => (
          <Form>
            <Box maxWidth="500px" display="flex" flexDirection="column" gap="16px">
              <Box>
                <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                  <Box flex={1}>
                    <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                      {t("forms.labels.nom")}
                    </Typography>
                  </Box>
                  <Box>
                    <Field name="last_name" type="input" placeholder={t("forms.labels.nom")} as={TextField} />
                    <ErrorMessage name="last_name" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                  <Box flex={1}>
                    <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                      {t("forms.labels.prenom")}
                    </Typography>
                  </Box>
                  <Box>
                    <Field name="first_name" type="input" placeholder={t("forms.labels.prenom")} as={TextField} />
                    <ErrorMessage name="first_name" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                </Box>
              </Box>

              <Box>
                <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                  <Box flex={1}>
                    <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                      EMAIL
                    </Typography>
                  </Box>
                  <Box>
                    <Field type="input" name="email" placeholder="EMAIL" as={TextField} />
                    <ErrorMessage name="email" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                  <Box flex={1}>
                    <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                      {t("forms.labels.utilisateur")}
                    </Typography>
                  </Box>
                  <Box>
                    <Field name="username" placeholder={t("forms.labels.utilisateur")} as={TextField} />
                    <ErrorMessage name="username" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                  <Box flex={1}>
                    <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                      {t("forms.labels.mot_de_passe")}
                    </Typography>
                  </Box>
                  <Box>
                    <Field name="password" placeholder={t("forms.labels.mot_de_passe")} as={TextField} />
                    <ErrorMessage name="password" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                  {t("forms.labels.cat_choice")}
                </Typography>
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkAllProd}
                      value={checkAllProd}
                      onChange={(e) => {
                        setCheckAllProd(e.target.checked);
                      }}
                    />
                  }
                  label={t("forms.labels.all_prod")}
                />
              </Box>
              {!checkAllProd && (
                <Box>
                  <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                    {t("forms.labels.seulement")}
                  </Typography>
                  <Box sx={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
                    {categories?.map((cat, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            value={cat?.value}
                            checked={values?.visible?.includes(cat.value) || false}
                            onChange={(e) => {
                              const newType = e.target.checked ? [...values?.visible, cat.value] : values?.visible?.filter((f) => f !== cat.value);
                              setFieldValue("visible", newType);
                            }}
                          />
                        }
                        label={cat?.name}
                      />
                    ))}
                  </Box>
                  <ErrorMessage name="visible" component={FormikErrorText} color={colors.main} width="250px" />
                </Box>
              )}
              {checkAllProd && (
                <Box>
                  <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                    {t("forms.labels.sauf")}
                  </Typography>
                  <Box sx={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
                    {categories?.map((cat, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            value={cat?.value}
                            checked={values?.visible?.includes(cat.value) || false}
                            onChange={(e) => {
                              const newType = e.target.checked ? [...values?.visible, cat.value] : values?.visible?.filter((f) => f !== cat.value);
                              setFieldValue("visible", newType);
                            }}
                          />
                        }
                        label={cat?.name}
                      />
                    ))}
                  </Box>
                  <ErrorMessage name="visible" component={FormikErrorText} color={colors.main} width="250px" />
                </Box>
              )}
              {success && <Alert severity="success">{success}</Alert>}
              {error && (
                <Alert severity="error">
                  <Box>
                    <List>
                      {error?.store_employee_credentials?.username && (
                        <ListItem>
                          {t("forms.labels.utilisateur")} : {error?.store_employee_credentials?.username}
                        </ListItem>
                      )}
                      {error?.store_employee_credentials?.password && (
                        <ListItem>
                          {t("forms.labels.mot_de_passe")} : {error?.store_employee_credentials?.password}
                        </ListItem>
                      )}
                      {error?.store_employee_credentials?.email && <ListItem>EMAIL : {error?.store_employee_credentials?.email}</ListItem>}

                      {error?.store_employee_profile?.last_name && (
                        <ListItem>
                          {t("forms.labels.nom")} : {error?.store_employee_profile?.last_name}
                        </ListItem>
                      )}
                      {error?.store_employee_profile?.first_name && (
                        <ListItem>
                          {t("forms.labels.prenom")} : {error?.store_employee_profile?.first_name}
                        </ListItem>
                      )}
                      {error?.store_employee_profile?.visible_categories && (
                        <ListItem>
                          {t("forms.labels.cat_choice")} : {error?.store_employee_profile?.visible_categories[0]}
                        </ListItem>
                      )}
                    </List>
                  </Box>
                </Alert>
              )}
              <Box>
                <Button type="submit" variant="primary">
                  {t("forms.submit.save")}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default AjoutUtilisateurs;
