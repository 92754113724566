import { Alert, Box, Button, TextField } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/colors";
import * as yup from "yup";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Urls } from "../../../api/Urls";
import Api from "../../../api/Api";
import Loading from "../../../components/ui/Loading";
const GestionAffichage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { user } = useAuthContext();
  const lang = localStorage?.getItem("i18nextLng");
  const [success, setSuccess] = useState("");
  const access_token = user?.access;
  const { t } = useTranslation();
  const [initial_values, setinitialValues] = useState({
    rate: "",
  });
  const validationSchema = yup.object({
    rate: yup?.number().required(t("validation_required")),
  });

  useEffect(() => {
    Api?.get(Urls?.GETMANAGERPREFSDAYS, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setLoading(false);
        setinitialValues({ rate: res?.data?.manager_days_preference });
      })
      .catch((err) => {
        setError(err?.response?.data?.details);
        setLoading(false);
      });
  }, []);
  //HANDLE SBUMIT CREATE MANAGER
  const handleSubmit = (values) => {
    setError(null);
    Api.patch(
      Urls.SubmitProcessRate,
      {
        manager_days_preference: values?.rate,
      },
      {
        headers: { "Content-Type": "application/json", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setSuccess(t("forms.success.update"));
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      })
      .catch((err) => {
        setError(err?.response?.data?.details);
      });
  };

  return (
    <Box>
      {loading && <Loading />}
      {error && <Alert severity="error">{error}</Alert>}
      {!loading && (
        <Formik initialValues={initial_values} validationSchema={validationSchema} enableReinitialize onSubmit={handleSubmit}>
          {({ errors, values, setFieldValue }) => (
            <Form>
              <Box maxWidth="500px" display="flex" flexDirection="column" gap="16px">
                <Box>
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                    <Box>
                      <Field name="rate" type="number" placeholder="30" as={TextField} />
                      <ErrorMessage name="rate" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                  </Box>
                </Box>

                {success && <Alert severity="success">{success}</Alert>}

                <Box>
                  <Button type="submit" variant="primary">
                    {t("forms.submit.save")}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};
export default GestionAffichage;
